.image-5-icon16as {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.po1{
  color: #1E1E1E;
  text-align: center;
  font-family: bold;
  line-height:50px;
  font-size: 16px;
}
.swatch56as {
  position: absolute;
  height: 79%;
  width: 90%;
  top: 12%;
  right: 2.78%;
  bottom: 10.34%;
  left: 2.60%;
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  margin: 0 auto;

}

.dlp-swatchas {
  font-size: var(--font-size-base);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-weight: 600;
  font-family: var(--font-inter);
  color: var(--color-gray-300);
  margin-top: 10px;
}

.swatch57as {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: 0.8px solid var(--color-gray-100);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.person-icon14as {
  position: absolute;
  height: 53.85%;
  width: 53.85%;
  top: 23.08%;
  right: 23.08%;
  bottom: 23.08%;
  left: 23.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent24as {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: 6.16%;
  right: 5.33%;
  bottom: 87.44%;
  left: 80.8%;
}
.swatch58as {
  height: 48.3%;
  margin: 0 auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: rgba(12, 12, 12, 0.205);
  width: 85%;
  opacity: 50%;
  margin-top: 150px;
}
.image-8-iconas {
  position: relative;
  margin: 0 auto;
  width: 355px;
  height: 355px;
  margin-bottom: 30px;
  margin-top: -355px;
}
.group-childas {
  position: absolute;
  top: 0;
  left: 1px;
  width: 21.5px;
  height: 21.5px;
}
.group-itemas {
  top: 2px;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-gray-400);
  width: 20px;
  height: 20px;
}
.b,
.group-inneras,
.group-itemas {
  position: absolute;
}
.group-inneras {
  top: 6px;
  left: 4px;
  border-radius: 50%;
  background-color: var(--grays-white-dark);
  width: 12px;
  height: 12px;
}
.bas {
  top: 2px;
  left: 8px;
  line-height: 21px;
}
.vector-parentas {
  position: absolute;
  top: 383px;
  left: 195px;
  width: 22.5px;
  height: 23px;
  text-align: left;
  font-size: var(--fonts-en-12-regular-size);
}
.b1as {
  position: relative;
  top: 2px;
  left: 7px;
  line-height: 21px;
}
.vector-containeras,
.vector-groupas {
  position: absolute;
  top: 372px;
  left: 158px;
  width: 22.5px;
  height: 23px;
  text-align: left;
  font-size: var(--fonts-en-12-regular-size);
}
.vector-containeras {
  top: 321px;
  left: 196px;
}
.app-icons9as {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.image9as {
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.div29as {
  position: relative;
  line-height: 22px;
}
.disclosure3as,
.title9as {
  position: relative;
  align-items: center;
}
.title9as {
  align-self: stretch;
  margin-top: 17px;
  width: 100%;
  flex: 1;
  font-size: var(--font-size-lg);
  line-height: 21px;
  font-weight: 500;
  font-family: var(--font-kalameh);
  color: var(--text-primary);
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.disclosure3as {
  width: 11px;
  line-height: 22px;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: none;
  justify-content: center;
  height: 18.2px;
  flex-shrink: 0;
}
.grabber19as,
.trailing-accessories9as {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trailing-accessories9as {
  width: 11px;
  height: 44px;
  flex-direction: row;
  text-align: center;
}
.grabber19as {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 22px;
  height: 22px;
}
.grabber18as {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
  text-align: center;
  font-family: var(--font-sf-pro);
}
.div30as,
.row-inset9as {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-inset9as {
  margin: 0 auto;
  border-radius: var(--br-base) var(--br-base) 0 0;
  background: linear-gradient(rgba(15, 15, 15, 0.377), rgba(15, 15, 15, 0.377)),
    rgba(15, 15, 15, 0.377);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  width: 85%;
  height: 60px;
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  text-align: left;
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-medium);
}
.div30as {
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  width: 22px;
  height: 22px;
}
.edit-button19as {
  width: 22px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 22px;
}
.edit-button18as {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  font-size: var(--font-size-smi);
}
.div31as,
.title10as {
  position: relative;
  line-height: 21px;
  font-family: var(--font-gilroy-medium);
  font-size: 23px;
  text-align: left;
}
.title10as {
  margin-top: 17px;
  align-self: stretch;
  flex: 1;
  font-size: var(--font-size-xl);
  line-height: 26px;
  font-weight: 500;
  font-family: var(--font-kalameh);
  color: var(--text-primary);
  display: -webkit-inline-box;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.disclosure4as,
.trailing-accessories10as {
  display: flex;
  align-items: center;
  justify-content: center;
}
.disclosure4as {
  width: 11px;
  position: relative;
  line-height: 22px;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 18.2px;
  flex-shrink: 0;
}
.trailing-accessories10as {
  height: 44px;
  flex-direction: row;
  font-family: var(--font-gilroy-medium);
}
.grabber20as {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
}
.row-inset10as {
  position: relative;
  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  width: 85%;
  height: 60px;
  margin: 0 auto;
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  color: var(--grays-white-dark);
  font-family: var(--font-sf-pro);
}
.checkmark6as,
.row-inset10as,
.trailing-accessories11as {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkmark6as {
  width: 18px;
  position: relative;
  line-height: 22px;
  height: 22px;
  flex-shrink: 0;
}
.trailing-accessories11as {
  height: 44px;
  flex-direction: row;
  font-size: var(--font-size-lgi);
  color: var(--text-primary);
  font-family: var(--font-gilroy-medium);
}
.edit-button22as,
.row-inset11as {
  flex-direction: row;
  align-items: center;
  font-family: var(--font-sf-pro);
}
.row-inset11as {
  position: relative;
  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  width: 85%;
  margin: 0 auto;
  height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  color: var(--grays-white-dark);
}
.edit-button22as {
  display: none;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  font-size: var(--font-size-smi);
}
.div35as,
.title12as {
  position: relative;
  line-height: 21px;
  font-family: var(--font-gilroy-medium);
  font-size: 23px;
  text-align: left;
}
.title12as {
  margin-top: 17px;
  align-self: stretch;
  flex: 1;
  font-size: var(--font-size-xl);
  line-height: 26px;
  font-weight: 500;
  font-family: var(--font-kalameh);
  display: -webkit-inline-box;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #94d13d;

}

.trailing-accessories12as {
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-lgi);
  color: var(--text-primary);
}
.grabber24as {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
  font-family: var(--font-sf-pro);
}
.row-inset12as {
  position: relative;
  border-radius: 0 0 var(--br-xl) var(--br-xl);
  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  width: 85%;
  margin: 0 auto;
  height: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-medium);
  backdrop-filter: blur(10px);
}
.swatch-parent25as {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: 6.16%;
  right: 79.73%;
  bottom: 87.44%;
  left: 6.4%;
}
.time16as {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time16as {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side16as {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera16as {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera16as,
.statusbar-dynamicisland16as,
.truedepth-camera16as {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera16as {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland16as {
  width: 125px;
  position: relative;
}
.dynamic-island16as {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal16as,
.wifi-icon16as {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon16as {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon16as {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery16as {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side16as,
.statusbar16as {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side16as {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar16as {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  font-size: var(--font-size-base);
  font-family: var(--font-gilroy-regular);
}
.main2as {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-semibold);
}
body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 375px) {
  .main2as {
    height:100vh;
  }

  .po1{
    line-height: 42px;
  }
  .image-8-iconas {
    margin-top: -240px;
    width: 235px;
    height: 235px;
  }

  .swatch58as {
    height: 290px;
    margin-top: 125px;
  }


}


/* 360 */

@media (max-width: 360px) {
  .main2as {
    height: 670px;
  }
  .swatch58as {
    margin-top: 130px;
    height: 295px;
  }
  .image-8-iconas {
    margin-top: -260px;
    width: 255px;
    height: 255px;
  }
.po1{
  line-height: 40px;
}
  .swatch56as{
    margin-top: 9px;
  }
}

/* 344 */

@media (max-width: 344px) {
  .swatch58as {
    margin-top: 130px;
  }

  .image-8-iconas {
    margin-top: -260px;
    width: 205px;
    height: 205px;
  }
  .row-innn {
    margin-top: -25px;
  }
}
.row-innn {
  overflow-y: scroll;
  height: 120px;
  width: 100%;
  margin-top: -30px;
}

.row-innn::-webkit-scrollbar {
  display: none;
}
