.overview1RTs {
  position: absolute;
  top: 3px;
  left: calc(50% - 36.5px);
  line-height: 22px;
}
body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.about-usX {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-700);
  font-family: var(--font-kalameh);
}
@media (max-width: 375px) {
  .about-usX {
    height: 100vh;
  }
}
@media (max-width: 375px) {
  .swatch100X {
    position: absolute;
    top: -20px;
  }

}
@media (max-width: 375px) {
  .swatch-parent61X {
    margin-top: 47px;
  }
}
@media (max-width: 375px) {
  .segmented-control4X {
    position: absolute;
    top: -35px;
    right: -3px;
  }
  .label29X,.label27X{
    font-size: 12px;
  }
}

@media (max-width: 360px) {
  .swatch100X {
    position: absolute;
    top: -10px;
  }
}
@media (max-width: 360px) {
  .swatch-parent61X {
    margin-top: 24px;
  }
}
@media (max-width: 360px) {
  .segmented-control4X {
    position: absolute;
    top: -10px;
    right: -2px;
  }
}

@media (max-width: 344px) {
  .swatch100X {
    position: absolute;
    top: 10px;
    height: 100px;
  }
}
@media (max-width: 344px) {
  .swatch-parent61X {
    margin-top: -24px;
  }
}
@media (max-width: 344px) {
  .segmented-control4X {
    position: absolute;
    top: 34px;
    right: -1px;
  }
}

.image-5-icon22X {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.swatch100X,
.swatch99X {
  position: relative;
  border-radius: var(--br-xl);
}
.swatch99X {
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  margin: 0 auto;
position: absolute;
top: 12%;
right: 5.15%;
bottom: 5.91%;
left: 4.90%;
  width: 90%;
  height: 79%;
}
.swatch100X {
  height: 60%;
  position: relative;
  margin: 0 auto;
  margin-top: 153px;
  background: linear-gradient(rgba(7, 7, 7, 0), rgba(7, 7, 7, 0)),
    rgba(22, 22, 22, 0.151);
  width: 85%;
  opacity: 96%;
  box-shadow: var(--recessed);
}

.slider-default11X {
  position: absolute;
  top: 204px;
  left: 323px;
  border-radius: var(--br-81xl);
  width: 12px;
  height: 420px;
  overflow: hidden;
  object-fit: contain;
}
.lorem-ipsum-dolor5X {
  position: relative;
  width: 85%;
  color: white;
  margin: 0 auto;
  line-height: 24px;
  font-weight: 500;
  font-family: var(--font-kalameh);
  text-align: justify;
  display: inline-block;
  margin-bottom: 200px;
  height: 300px;
  overflow-x: auto;
  padding: 10px;
}
.lorem-ipsum-dolor5::-webkit-scrollbarx {
  background: linear-gradient(rgba(0, 0, 0, 0.568), rgba(0, 0, 0, 0.61));
  color: aliceblue;
  z-index: 99;
  width: 12px;
  border-radius: 30px;
}
.lorem-ipsum-dolor5::-webkit-scrollbar-thumbx {
  background: linear-gradient(#ffffff50, #dbdbdb), rgba(207, 207, 207, 0.23);
  border-radius: 30px;
  height: 160px;
}
.div103X {
  width: 8px;
  display: none;
}
.div103X,
.label27X {
  position: relative;
  line-height: 20px;
  background: linear-gradient(#969696, #b8b8b8), rgba(255, 255, 255, 0.425);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-14X {
  flex: 1;
  border-radius: var(--br-81xl);
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-10xs);
}
.div105X {
  width: 8px;
  display: none;
}
.div105X,
.label29X {
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-34X,
.segmented-control4X {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.button-34X {
  flex: 1;
  box-shadow: var(--blur-shadow-small);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(122, 171, 216, 0.281);
  height: 36px;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-10xs);
}
.segmented-control4X {

  position: relative;
  width: 85%;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: var(--br-81xl);
  background: rgba(12, 12, 12, 0.205);
  height: 44px;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
  font-size: var(--font-size-mini);
  color: var(--text-primary);
}
.profile4X {
  position: absolute;
  top: 65px;
  left: calc(50% - 27.5px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-300);
  font-weight: 600;
}
.swatch101X {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.person-icon22X {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent60X {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 6.16%;
  right: 5.33%;
  bottom: 87.44%;
  left: 80.8%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch102X {
  position: relative;
  margin: 0 auto;
  height: 1025%;
  width: 9.76%;
  margin-top: 3px;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.about-us1X {
  width: 100%;
  height: 30px;
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.3);
  position: relative;
  color: white;
  margin-top: -265px;
  line-height: 30px;
}
.swatch-parent61X {
  position: absolute;
  height: 3.45%;
  width: 85%;
  top: 10.35%;
  right: 7.73%;
  bottom: 79.8%;
  left: 7.73%;
  font-size: var(--font-size-mid);
}
.swatch-parent62X,
.swatch-parent63X {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 6.16%;
  right: 79.73%;
  bottom: 87.44%;
  left: 6.4%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch-parent63X {
  top: -27.09%;
  right: 116%;
  bottom: 120.69%;
  left: -29.87%;
}
.time24X {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time22X {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side22X {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera22X {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera22X,
.statusbar-dynamicisland22X,
.truedepth-camera22X {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera22X {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland22X {
  width: 125px;
  position: relative;
}
.dynamic-island22X {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal22X,
.wifi-icon22X {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon22X {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon22X {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery22X {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side22X,
.statusbar22X {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side22X {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar22X {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  font-size: var(--font-size-base);
  color: var(--system-background-dark-base-primary);
  font-family: var(--font-gilroy-regular);
}
