.image-5-icon1a {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.po3{
  color: #1E1E1E;
  text-align: center;
  font-family: bold;
  font-weight: bold;
  line-height:50px;
  font-size: 16px;
  position: absolute;
top: 11.50%;
right: 2.78%;
bottom: 10.34%;
left: 2.60%;
}
.swatch6a {
  position: absolute;
  top: 12%;
  right: 5.35%;
  bottom: 5.91%;
  left: 4.90%;
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  width: 90%;
  height: 79%;
}
.diva,
.edit-button1a {
  width: 22px;
  height: 22px;
}
.diva {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-button1a {
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
}
.edit-buttona {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  font-size: var(--font-size-smi);
}
.app-iconsa {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.imagea {
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.disclosurea,
.titlea {
  position: relative;
  line-height: 22px;
}
.titlea {
  margin-top: 21px;
  align-self: stretch;
  flex: 1;
  font-size: var(--font-size-base);
  font-family: var(--font-gilroy-medium);
  color: var(--text-primary);
  text-align: left;
  display: -webkit-inline-box;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.disclosurea {
  margin-top: 17px;
  width: 11px;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 18.2px;
  flex-shrink: 0;
}
.disclosurea,
.grabber1a,
.trailing-accessoriesa {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trailing-accessoriesa {
  height: 44px;
  flex-direction: row;
  font-family: var(--font-gilroy-medium);
}
.grabber1a {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 22px;
  height: 22px;
}
.grabbera {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
}
.title1a {
  align-items: center;
  overflow: hidden;
}
.row-inseta {
  width: 100%;
  position: relative;
  border-radius:10px ;
  background: rgba(28, 44, 63, 0.363);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 66px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 0;
  font-size: var(--font-size-mid);
  color: var(--grays-white-dark);
}
.title1a {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 22px;
  font-family: var(--font-gilroy-medium);
  color: var(--text-primary);
  text-align: left;
  display: -webkit-inline-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.edit-button4a,
.row-inset1a {
  flex-direction: row;
  align-items: center;
  color: var(--grays-white-dark);
}
.row-inset1a {
  width: 100%;
  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 1;
  font-size: var(--font-size-mid);
}
.edit-button4a {
  display: none;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
}
.title2a {
  margin-top: 21px;
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 22px;
  font-family: var(--font-gilroy-medium);
  text-align: left;
  display: -webkit-inline-box;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.checkmarka,
.trailing-accessories2a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkmarka {
  margin-top: 17px;
  width: 18px;
  position: relative;
  line-height: 22px;
  height: 22px;
  flex-shrink: 0;
}
.trailing-accessories2a {
  height: 44px;
  flex-direction: row;
  font-size: var(--font-size-lgi);
  font-family: var(--font-gilroy-medium);
}
.grabber4a {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
  font-size: var(--font-size-mid);
}
.row-inset2a,
.title3a {
  align-items: center;
  overflow: hidden;
}
.row-inset2a {
  width: 100%;
  background: rgba(28, 44, 63, 0.363);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 64px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 2;
}
.title3a {
  margin-top: 17px;
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 22px;
  font-family: var(--font-gilroy-medium);
  white-space: pre-wrap;
  text-align: left;
  display: -webkit-inline-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.edit-button8a,
.row-inset3a {
  flex-direction: row;
  align-items: center;
}
.row-inset3a {
  width: 100%;
  background: rgba(28, 44, 63, 0.363);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 3;
}
.edit-button8a {
  display: none;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  font-size: var(--font-size-smi);
  color: var(--grays-white-dark);
}
.row-inset4a,
.title4a {
  overflow: hidden;
  align-items: center;
}
.title4a {
  margin-top: 17px;
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 22px;
  font-family: var(--font-gilroy-medium);
  text-align: left;
  display: -webkit-inline-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.row-inset4a {
  width: 100%;
  background: linear-gradient(rgba(15, 15, 15, 0.377), rgba(15, 15, 15, 0.377)),
    rgba(15, 15, 15, 0.377);
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 4;
  font-size: var(--font-size-base);
}
.row-inset4a,
.row-inset5a,
.row-inset6a {
  border-bottom: 1px solid var(--separators-separator);
  height: 64px;
}
.row-inset5a {
  width: 100%;
  background: linear-gradient(rgba(15, 15, 15, 0.377), rgba(15, 15, 15, 0.377)),
    rgba(15, 15, 15, 0.377);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 5;
}
.row-inset6a {
  z-index: 6;
}
.row-inset6a,
.row-inset7a,
.row-inset8a {
  width: 100%;
  background: rgba(28, 44, 63, 0.363);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.row-inset7a {
  border-bottom: 1px solid var(--separators-separator);
  height: 64px;
  z-index: 7;
}
.row-inset8a {
  border-radius: 0 0 var(--br-base) var(--br-base);
  height: 66px;
  z-index: 8;
  font-size: var(--font-size-mid);
  background: rgba(28, 44, 63, 0.363);
}
.vuesaxoutlinearrow-right-icona,
.vuesaxoutlinearrow-right-icon1a,
.vuesaxoutlinearrow-right-icon2a {
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 23px;
  left: 251px;
  height: 24px;
  z-index: 9;
}
.vuesaxoutlinearrow-right-icon1a,
.vuesaxoutlinearrow-right-icon2a {
  top: 89px;
  z-index: 10;
}
.vuesaxoutlinearrow-right-icon2a {
  top: 154px;
  z-index: 11;
}
.vuesaxoutlinearrow-right-icon3a,
.vuesaxoutlinearrow-right-icon4a,
.vuesaxoutlinearrow-right-icon5a {
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 219px;
  left: 251px;
  height: 24px;
  z-index: 12;
}
.vuesaxoutlinearrow-right-icon4a,
.vuesaxoutlinearrow-right-icon5a {
  top: 284px;
  z-index: 13;
}
.vuesaxoutlinearrow-right-icon5a {
  top: 349px;
  z-index: 14;
}
.vuesaxoutlinearrow-right-icon6a,
.vuesaxoutlinearrow-right-icon7a,
.vuesaxoutlinearrow-right-icon8a {
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 414px;
  left: 251px;
  height: 24px;
  z-index: 15;
}
.vuesaxoutlinearrow-right-icon7a,
.vuesaxoutlinearrow-right-icon8a {
  top: 479px;
  z-index: 16;
}
.vuesaxoutlinearrow-right-icon8a {
  top: 545px;
  z-index: 17;
}
.slider-defaulta {
  position: relative;
  height: 590px;
}
.section-1a {
  position: relative;
  height: 400px;
  overflow-y: scroll;
  padding: 5px;
  overflow-x: auto;
  opacity: 60%;
  width: 85%;
  margin: 0 auto;
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1px;
}
.section-1a::-webkit-scrollbar{
  display: none;
}

.section-1::-webkit-scrollbara {
  display: none;
  background: linear-gradient(rgba(0, 0, 0, 0.568), rgba(0, 0, 0, 0.61));
  color: aliceblue;
  z-index: 99;
  width: 12px;
  border-radius: 30px;
}
.section-1::-webkit-scrollbar-thumba {
  background: linear-gradient(#ffffff50, #dbdbdb), rgba(207, 207, 207, 0.23);
  border-radius: 30px;
  height: 160px;
}
.slider-defaulta {
  top: 154px;
  left: 317px;
  border-radius: var(--br-81xl);
  width: 12px;
  overflow: hidden;
  object-fit: contain;
}
.swatch7a {
  position: absolute;
  height: 95.19%;
  width: 105.05%;
  top: 0;
  right: -105.05%;
  bottom: 4.81%;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: 0.8px solid var(--color-gray-100);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.arrowbackward-icona {
  position: absolute;
  height: 53.85%;
  width: 53.94%;
  top: 23.08%;
  right: 23.03%;
  bottom: 23.08%;
  left: 23.03%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.group-diva,
.swatch-parent2a {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: 6.16%;
  right: 79.75%;
  bottom: 87.44%;
  left: 6.39%;
}
.swatch-parent2a {
  right: 5.32%;
  left: 80.81%;
}
.mandiblea,
.time1a {
  position: absolute;
  letter-spacing: -0.32px;
  line-height: 21px;
}
.mandiblea {
  top: 66px;
  left: calc(50% - 42.5px);
  font-size: var(--font-size-xl);
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-800);
  text-align: left;
}
.time1a {
  top: 1px;
  left: 0;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time1a {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side1a {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera1a {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera1a,
.statusbar-dynamicisland1a,
.truedepth-camera1a {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera1a {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland1a {
  width: 125px;
  position: relative;
}
.dynamic-island1a {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal1a,
.wifi-icon1a {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon1a {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon1a {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery1a {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side1a,
.statusbar1a {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side1a {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar1a {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  font-size: var(--font-size-base);
  color: var(--system-background-dark-base-primary);
  font-family: var(--font-gilroy-regular);
}
.lista {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-semibold);
}
body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 375px) {
  .lista {
    height: 100vh;
  }
}

@media (max-width: 344px) {
  .lista {
    height: 812px;
  }
  .swatch6a {
    margin-top: -20px;
  }
}
