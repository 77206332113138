.image-5-icon14J {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.pop-up-exit-itemJ {
  position: absolute;
  top: 50px;
  left: 0;
  backdrop-filter: blur(60px);
  border-radius: var(--br-lgi);
  background-color: var(--color-gray-500);
  width: 375px;
  height: 762px;
}
.swatch49J {
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  position: relative;
  margin: 0 auto;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  margin-top: 100px;
  width: 100%;
  height: 425px;
}
.result-testJ {
  position: relative;
  margin-top: -400px;
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--text-primary);
  text-align: center;
}
.swatch50J {
  position: absolute;
  height: 32px;
  width: 100%;
  top: 0;
  right: 0%;
  bottom: -925%;
  left: 0;
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.3);
}
.score1J {
  position: relative;
  text-align: center;
  line-height: 22px;
}
.swatch-parent21J {
  position: absolute;
  height: 6.59%;
  width: 85.67%;
  top: 15.29%;
  right: 7.16%;
  bottom: 78.12%;
  left: 7.16%;
}
.result1J {
  position: relative;
  text-align: center;
  line-height: 22px;
}
.swatch-parent22J {
  position: absolute;
  height: 6.59%;
  width: 85.67%;
  top: 45.41%;
  right: 7.16%;
  bottom: 48%;
  left: 7.16%;
}
.swatch52J,
.swatch53J,
.swatch54J {
  position: relative;
  height: 32px;
  width: 85%;
  margin-top: -36px;
  display: flex;
  margin: 0 auto;
  box-shadow: var(--recessed);
  border-radius: var(--br-3xl);
  background: linear-gradient(rgba(7, 7, 7, 0), rgba(7, 7, 7, 0)),
    rgba(22, 22, 22, 0.151);
}
.swatch53J,
.swatch54J {
  top: 56.71%;
  bottom: -24.24%;
}
.swatch54J {
  top: 70.82%;
  bottom: -38.35%;
}
.div28J {
  position: relative;
  text-align: center;
  margin-top: 83px;
  font-size: 32px;
  line-height: 32px;
  font-family: var(--font-gilroy-bold);
}
.ok1J {
  position: relative;
  line-height: 22px;
}
.button-text32J,
.sign-in-btn32J {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-text32J:hover {
  background: rgba(255, 255, 255, 0.171);
}
.button-text32J:focus {
  outline-color: transparent;
  outline-style: solid;
}
.button-text32J:active {
  background: rgba(255, 255, 255, 0.171);
  box-shadow: var(--recessed);
  
}
.button-text32J {
  width: 85%;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(122, 171, 216, 0.281);
  height: 44px;
  box-shadow: var(--btn-rmazani);
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.sign-in-btn32J {
  position: relative;
  margin-top: 210px;
}
.false1J {
  top: 250px;
  left: 51px;
}
.false1J,
.question2J,
.question3J,
.true1J {
  position: absolute;
  line-height: 22px;
}
.true1J {
  top: 310px;
  left: 51px;
  color: var(--color-gray-600);
}
.question2J,
.question3J {
  top: 249px;
}
.question3J {
  top: 310px;
  left: 191px;
  color: var(--color-gray-600);
}
.swatch-parent20J,
.time14J {
  position: relative;
  margin: 0 auto;
  margin-top: 70px;
  width: 85%;
  height: 425px;
}
.time14J {
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time14J {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side14J {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera14J {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera14J,
.statusbar-dynamicisland14J,
.truedepth-camera14J {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera14J {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland14J {
  width: 125px;
  position: relative;
}
.dynamic-island14J {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal14J,
.wifi-icon14J {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon14J {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon14J {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery14J {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side14J,
.statusbar14J {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side14J {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar14J {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  font-size: var(--font-size-base);
  color: var(--system-background-dark-base-primary);
  font-family: var(--font-gilroy-regular);
}
.pop-up-exit1J {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-semibold);
}
body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
