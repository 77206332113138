.profile4kl {
  border: none;
  position: absolute;
  top: 27px;
  left: calc(50% - 70.5px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-300);
  font-weight: 600;
}
.swatch-parent60KL {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 9px;
  right: 5.33%;
  bottom: 87.44%;
  left: 80.8%;
  backdrop-filter: blur(0px);
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch101KL {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  border: none;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.person-icon22KL {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  border: none;
}
.button-text30K{
  border: none;
}.button-text30K:hover{
  outline: solid 1px #ffffff57;
  cursor: pointer;
}
.button-text31K{
  border: none;
}.button-text31K:hover{
  outline: solid 1px #ffffff57;
  cursor: pointer;
}
.swatch-parent62KL,
.swatch-parent63KL {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 1.16%;
  right: 77.73%;
  bottom: 87.44%;
  left: 6.4%;
  backdrop-filter: blur(0px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch-parent63KL {
  top: -27.09%;
  right: 116%;
  bottom: 120.69%;
  left: -29.87%;
  border: none;
}
