code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Yekan";
  src: url("../src/asset/fonts/Gilroy-Regular.ttf");
}
* {
  font-family: Yekan;
  font-size: 16px;
}
body {
  font-family: Yekan;
  font-size: 16px;
}
@font-face {
  font-family: "BOLD";
  src: url("../src/asset/fonts/Gilroy-Bold.ttf");
}
* {
  font-family: BOLD;
  font-size: 16px;
}
