.image-5-icon18RT {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.swatch63RT,
.swatch64RT {
  position: absolute;
  border-radius: var(--br-xl);
}
.swatch63RT {
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  position: absolute;
  height: 79%;
  width: 90%;
  top: 12%;
  right: 5.15%;
  bottom: 5.91%;
  left: 4.90%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
}
.swatch64RT {
  margin-top: 5px;
  height: 30px;
  width: 100%;
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.3);
}
.overview1RT {
  line-height: 40px;
}
.swatch-parent28RT {
  position: absolute;
  height: 3.45%;
  width: 85%;
  top: 12.75%;
  right: 7.73%;
  bottom: 79.8%;
  left: 7.73%;
  text-align: center;
  font-size: var(--font-size-mid);
}
.div36RT {
  width: 8px;
  display: none;
}
.div36RT,
.label15RT {
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.button-1RT {
  flex: 1;
  box-shadow: var(--blur-shadow-small);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(122, 171, 216, 0.281);

  box-sizing: border-box;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-10xs);
}
.div37RT {
  width: 8px;
  display: none;
}
.div37RT,
.label16RT {
  position: relative;
  line-height: 20px;
  background: linear-gradient(#969696, #b8b8b8), rgba(255, 255, 255, 0.425);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-2RT {
  flex: 1;
  border-radius: var(--br-81xl);
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-10xs);
}
.profileRT {
  position: absolute;
  text-align: center;
}
.segmented-controlRT {
  position: relative;
  text-align: center;
  margin: 0 auto;
  border-radius: var(--br-81xl);
  background: rgba(12, 12, 12, 0.205);
  width: 85%;
  top: 641px;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  color: var(--text-primary);
  margin-top: 29px;
}
.profileRT {
  top: 65px;
  left: calc(50% - 27.5px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-300);
}
.swatch65RT {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: 0.8px solid var(--color-gray-100);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.person-icon16RT {
  position: absolute;
  height: 53.85%;
  width: 53.85%;
  top: 23.08%;
  right: 23.08%;
  bottom: 23.08%;
  left: 23.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent29RT,
.swatch-parent30RT {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: 6.16%;
  right: 5.33%;
  bottom: 87.44%;
  left: 80.8%;
}
.swatch-parent30RT {
  right: 79.73%;
  left: 6.4%;
}
.swatch67RT {
  height: 38px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  box-shadow: var(--recessed);
  border-radius: var(--br-3xl);
  background: rgba(12, 12, 12, 0.205);
}
.personbadgeclock-iconRT {
  position: absolute;
  height: 60%;
  width: 8.36%;
  top: 20%;
  right: 87.46%;
  bottom: 20%;
  left: 4.18%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.study-timeRT {
  position: absolute;
  top: 10px;
  left: 220px;
  line-height: 20px;
}
.minRT {
  position: relative;
  top: 10px;
  margin-right: 5px;
  text-align: right;
  line-height: 20px;
}
.study-timeRT {
  left: 45px;
  font-size: var(--font-size-sm);
  text-align: center;
  opacity: 0.6;
}
.swatch-parent31RT {
  position: absolute;
  height: 4.93%;
  width: 85%;
  top: 26%;
  right: 7.73%;
  bottom: 65.02%;
  left: 7.73%;
}

.read-pageRT {
  position: absolute;
  top: 10px;
  left: 193px;
  line-height: 20px;
}
.div39RT {
  position: relative;
  margin-top: -27px;
  text-align: right;
  margin-right: 5px;
  line-height: 20px;
}
.read-pageRT {
  left: 41px;
  font-size: var(--font-size-sm);
  opacity: 0.6;
}
.swatch-parent32RT {
  position: absolute;
  height: 4.93%;
  top: 33%;
  bottom: 57.64%;
  width: 85%;
  right: 7.73%;
  left: 7.73%;
}
.answered-easy-qsRT {
  position: relative;
  text-align: left;
  margin-left: 20px;
  line-height: 58px;
}
.answered-easy-qs1RT {
  position: relative;
  top: -28px;
  text-align: left;
  margin-left: 48px;
  line-height: 20px;
  font-size: var(--font-size-sm);
  opacity: 0.6;
}

.div40RT {
  position: relative;
  top: -10px;
  margin-right: 5px;
  text-align: right;
  line-height: 20px;
  margin-top: -20px;
}
.answered-easy-qsRT {
  top: -10px;
  left: 30px;
  font-size: var(--font-size-sm);
  opacity: 0.6;
}
.swatch-parent33RT {
  top: 40%;
  bottom: 50.25%;
  position: absolute;
  height: 4.93%;
  width: 85%;

  right: 7.73%;

  left: 7.73%;
}
.div41RT {
  position: relative;
  top: -10px;
  margin-right: 5px;
  text-align: right;
  line-height: 20px;
  margin-top: -20px;
}
.swatch-parent34RT,
.swatch-parent35RT {
  top: 47%;
  bottom: 42.86%;
  position: absolute;
  height: 4.93%;
  width: 85%;
  right: 7.73%;
  left: 7.73%;
}
.swatch-parent35RT {
  top: 54%;
  bottom: 35.47%;
}
.div43RT {
  position: relative;
  top: -10px;
  margin-right: 5px;
  text-align: right;
  line-height: 20px;
  margin-top: -20px;
}
.swatch-parent36RT {
  top: 61%;
  bottom: 28.08%;
  position: absolute;
  height: 4.93%;
  width: 85%;
  right: 7.73%;
  left: 7.73%;
}
.swatch-parent45RT {
  top: 19%;
  bottom: 28.08%;
  position: absolute;
  height: 4.93%;
  width: 85%;
  right: 7.73%;
  left: 7.73%;
}
.mehdi-zeynaliRT {
  position: relative;
  top: 10px;
  text-align: right;
  margin-right: 5px;
  line-height: 20px;
}
.swatch-parent37RT {
  top: 17.96%;
  bottom: 72.41%;
  text-align: center;
  position: absolute;
  height: 4.93%;
  width: 85%;
  right: 7.73%;
  left: 7.73%;
}
.div44RT {
  position: relative;
  top: -10px;
  margin-right: 5px;
  text-align: right;
  line-height: 20px;
  margin-top: -20px;
}
.swatch-parent38RT {
  top: 68%;
  bottom: 20.69%;
  position: absolute;
  height: 4.93%;
  width: 85%;
  right: 7.73%;
  left: 7.73%;
}

.swatch-parent39RT {
  top: 75%;
  bottom: 20.69%;
  position: absolute;
  height: 4.93%;
  width: 85%;
  right: 7.73%;
  left: 7.73%;
}
.time18RT {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time18RT {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side18RT {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera18RT {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera18RT,
.statusbar-dynamicisland18RT,
.truedepth-camera18RT {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera18RT {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland18RT {
  width: 125px;
  position: relative;
}
.dynamic-island18RT {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal18RT,
.wifi-icon18RT {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon18RT {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon18RT {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery18RT {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side18RT,
.statusbar18RT {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side18RT {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar18RT {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--system-background-dark-base-primary);
  font-family: var(--font-gilroy-regular);
}
.overviewRT {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-semibold);
}
body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 375px) {
  .overviewRT {
    height: 100vh;
  }
  .label15RT,.label16RT {

    font-size: 12px;
  }

  .segmented-controlRT {
    position: absolute;
    top: 519px;
    right: 25px;
  }

}

/* 360 */
@media (max-width: 360px) {
  .segmented-controlRT {
    position: absolute;
    top: 588px;
    right: 25px;
  }
}

/* 344 */
@media (max-width: 344px) {
  .segmented-controlRT {
    position: absolute;
    top: 716px;
    right: 25px;
  }

}
