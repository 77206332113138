
.ll{
     position: absolute;
     top: 75%;
     left: calc(50% - 27px);
}
.imgload{
     width: 100%;
     height: 100vh;
     display: flex;
     position: relative;
}
@media (max-width:375px) {

}
