.profile4 {
  border: none;
  position: absolute;
  top: 27px;
  left: calc(50% - 34px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-300);
  font-weight: 600;
}

.profile44 {
  border: none;
  position: absolute;
  top: 27px;
  left: calc(50% - 27px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-300);
  font-weight: 600;
}


.profile4X2 {
  position: absolute;
  top: 27px;
  left: calc(50% - 48px);
  font-size: var(--font-size-xl);
  width: auto;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-300);
  font-weight: 600;
  border: none;
  outline: none;
}



.profile411 {
  position: absolute;
  top: 27px;
  left: calc(45% - 29.5px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-300);
  font-weight: 600;
  border: none;
  outline: none;
}

.swatch-parent60 {
  position: absolute;
  border: none;
  width: 50px;
  height: 50px;
  top: 1.16%;
  right: 5.33%;
  bottom: 87.44%;
  left: 80.8%;
  border-radius: 50%;
  backdrop-filter: blur(0px);
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch-parent601 {
  position: absolute;
  border: none;
  width: 50px;
  height: 50px;
  top:  9px;
  right: 5.33%;
  bottom: 87.44%;
  left: 80.8%;
  border-radius: 50%;
  backdrop-filter: blur(0px);
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}

.swatch101 {
  border: none;
  outline: none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.person-icon22 {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  outline: none;
  border: none;
}
.swatch-parent62,
.swatch-parent63 {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 1.16%;
  right: 77.73%;
  bottom: 87.44%;
  left: 6.4%;
  backdrop-filter: blur(0px);
  border-radius: var(--br-13xl);
  outline: none;
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch-parent622{
  position: absolute;
  height: 50px;
  width: 50px;
  top: 9px;
  right: 77.73%;
  bottom: 87.44%;
  left: 6.4%;
  backdrop-filter: blur(0px);
  border-radius: var(--br-13xl);
  outline: none;
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch-parent63 {
  top: -27.09%;
  right: 116%;
  bottom: 120.69%;
  left: -29.87%;
  outline: none;
  border: none;
}
