.image-5-icon22V {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.swatch100V,
.swatch99V {
  position: relative;
  border-radius: var(--br-xl);
}
.swatch99V {
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 80px;
  width: 90%;
  height: 75.37%;
}
.swatch100V {
  height: 39%;
  position: relative;
  margin: 0 auto;
  margin-top: -558px;
  width: 85%;
  overflow-x: auto;
}
.question-1V {
  position: relative;
  letter-spacing: -0.32px;
  line-height: 45px;
  color: var(--text-primary);
  text-align: left;

}
.button-text11V,
.sign-in-btn11V {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sign-in-btn11V {
  position: relative;
  margin-top: -585px;
  font-size: var(--font-size-mid);
  color: var(--color-gray-400);
  font-family: var(--font-gilroy-semibold);
  float: right;
  margin-right: 30px;
}
.button-text11V {
  width: 66px;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(94, 94, 94, 0.07), rgba(94, 94, 94, 0.07)),
    rgba(255, 255, 255, 0.158);
  height: 36px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  color: white;
}
.button-text11V:hover {
  background: rgba(255, 255, 255, 0.171);
}
.button-text11V:focus {
  outline-color: transparent;
  outline-style: solid;
}
.button-text11V:active {
  background: rgba(255, 255, 255, 0.171);
  box-shadow: var(--recessed);
  
}
.slider-default11V {
  position: absolute;
  top: 204px;
  left: 323px;
  border-radius: var(--br-81xl);
  width: 12px;
  height: 420px;
  overflow: hidden;
  object-fit: contain;
}
.lorem-ipsum-dolor5V {
  position: relative;
  width: 95%;
  color: white;
  margin: 0 auto;
  line-height: 24px;
  font-weight: 500;
  font-family: var(--font-kalameh);
  text-align: justify;
  display: inline-block;
  margin-bottom: 200px;
}
.swatch100::-webkit-scrollbarv {
  background: linear-gradient(rgba(63, 63, 63, 0.568), rgba(58, 58, 58, 0.61));
  color: aliceblue;
  z-index: 99;
  width: 12px;
  border-radius: 30px;
}
.swatch100::-webkit-scrollbar-thumbv {
  background: linear-gradient(#ffffff50, #dbdbdb), rgba(207, 207, 207, 0.23);
  border-radius: 30px;
  height: 160px;
}
.div103V {
  width: 8px;
  display: none;
}
.sign-in-btn-parentV {
  position: relative;

  width: 100%;
  height: 212px;
  font-size: var(--font-size-mid);
  font-family: var(--font-gilroy-semibold);
}
.button-text7V {
  display: flex;

  align-items: center;
}
.button-text7V {
  width: 85%;
  margin: 0 auto;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
}
.answerV {
  position: relative;
  line-height: 22px;
}
.swatch67RTt {
  height: 38px;
  width: 100%;
  position: relative;
}
.swatch-parent34RTt {
  top: 12%;
  bottom: 42.86%;
  position: absolute;
  height: 4.93%;
  width: 85%;
  right: 7.73%;
  left: 7.73%;
}
.answered-easy-qs1RTt {
  position: relative;
  top: -28px;
  text-align: left;
  margin-left: 20px;
  line-height: 20px;
  font-size: var(--font-size-sm);

}
.div103V,
.label27V {
  position: relative;
  line-height: 20px;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-14V {
  flex: 1;
  border-radius: var(--br-81xl);
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-10xs);
}
.div105V {
  width: 8px;
  display: none;
}
.div105V,
.label29V {
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-34V,
.segmented-control4V {
  overflow: hidden;
  z-index: 99;
}
.image-15-icon1V {
  position: relative;
  margin: 0 auto;
  border-radius: var(--br-xs);
  width: 95%;
  height: 145px;
  object-fit: cover;
}
.button-34V {
  flex: 1;
  box-shadow: var(--blur-shadow-small);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  border: 0.5px solid var(--windows-stroke-glass-specular);
  height: 36px;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-10xs);
}

.profile4V {
  position: absolute;
  top: 65px;
  left: calc(50% - 27.5px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-300);
  font-weight: 600;
}
.swatch101V {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.person-icon22V {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent60V {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 6.16%;
  right: 5.33%;
  bottom: 87.44%;
  left: 80.8%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch102V {
  position: relative;
  margin: 0 auto;
  height: 1025%;
  width: 9.76%;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.about-us1V {
  width: 100%;
  height: 30px;
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.3);
  position: relative;
  color: white;
  margin-top: -270px;
  line-height: 22px;
}
.swatch-parent61V {
  position: absolute;
  height: 3.45%;
  width: 76.53%;
  top: 10.75%;
  right: 14.73%;
  bottom: 79.8%;
  left: 13.73%;
  font-size: var(--font-size-mid);
}
.swatch-parent62V,
.swatch-parent63V {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 6.16%;
  right: 79.73%;
  bottom: 87.44%;
  left: 6.4%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch-parent63V {
  top: -27.09%;
  right: 116%;
  bottom: 120.69%;
  left: -29.87%;
}
.time24V {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time22V {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side22V {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera22V {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera22V,
.statusbar-dynamicisland22V,
.truedepth-camera22V {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera22V {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland22V {
  width: 125px;
  position: relative;
}
.dynamic-island22V {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal22V,
.wifi-icon22V {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon22V {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon22V {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery22V {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side22V,
.statusbar22V {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side22V {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar22V {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  font-size: var(--font-size-base);
  color: var(--system-background-dark-base-primary);
  font-family: var(--font-gilroy-regular);
}
.about-usV {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-semibold);
}
body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 375px) {
  .about-usV {
    height: 100vh;
  }
  .question-1V {
    position: relative;
    line-height: 75px;

  
  }
  .segmented-control4V {
    margin-top: -50px;
  }
  .swatch100V {
    margin-top: -450px;
  }
  .sign-in-btn11V {
    margin-top: -480px;
  }
}

@media (max-width: 360px) {
  .swatch100V {
    margin-top: -490px;
  }
  .sign-in-btn11V {
    margin-top: -520px;
  }
  .question-1V {
    position: relative;
    line-height: 60px;
  }
}

@media (max-width: 344px) {
  .swatch100V {
    margin-top: -530px;
  }
  .sign-in-btn11V {
    margin-top: -578px;
  }
  .swatch99V {
    height: 68%;
  }
  .question-1V {
    position: relative;
    line-height: 30px;
  }
}
