.image-5-icon6lo {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}

.swatch22lo {
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 350px;
  width: 85%;
  height: 178px;
}
.person-icon6lo,
.value1lo {
  position: relative;
  overflow: hidden;
}
.person-icon6lo {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}
.value1lo {
  flex: 1;
  line-height: 22px;
  font-weight: 300;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.username1lo {
  position: relative;
  margin: 0 auto;
  margin-top: -158px;
  border-radius: var(--br-xs);
  background: rgba(12, 12, 12, 0.205);
  width: 70%;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  outline: none;
  border: none;
  color: white;
}
input::placeholder {
  position: relative;
  font-weight: 600;
  background: linear-gradient(#ffffff56, #ffffff52), rgba(255, 255, 255, 0.027);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.label4lo {
  position: relative;
  line-height: 22px;
}
.button-text4lo,
.sign-in-btn4lo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-text4lo {
  position: relative;
  width: 100%;
  margin: 0 auto;
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(122, 171, 216, 0.281);
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  color: white;
  
  cursor: pointer;
}

.sign-in-btn4lo {
  position: relative;
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
  box-shadow: var(--btn-rmazani);
  border-radius: var(--br-481xl);
  text-align: center;
  font-size: var(--font-size-mid);
  color: var(--text-primary);
  font-family: var(--font-gilroy-semibold);
}


.sign-in-btn4lo:hover {
  background: rgba(255, 255, 255, 0.171);
}
.sign-in-btn4lo:focus {
  outline-color: transparent;
  outline-style: solid;
}
.sign-in-btn4lo:active {
  background: rgba(255, 255, 255, 0.171);
  box-shadow: var(--recessed);
  
}

.inline-message-childlo {
  width: 16px;
  position: relative;
  height: 15px;
}
.div9lo,
.inline-messagelo {
  display: flex;
  align-items: center;
}
.div9lo {
  flex: 1;
  position: relative;
  line-height: 14px;
  height: 16px;
}
.inline-messagelo {
  position: relative;
  width: 80%;
  margin: 0 auto;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0;
  box-sizing: border-box;
  gap: var(--gap-9xs);
  font-size: var(--fonts-en-12-regular-size);
  color: var(--color-red);
  font-family: var(--fonts-en-12-regular);
}
.time6lo {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time6lo {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side6lo {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera6lo {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera6lo,
.statusbar-dynamicisland6lo,
.truedepth-camera6lo {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera6lo {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland6lo {
  width: 125px;
  position: relative;
}
.dynamic-island6lo {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal6lo,
.wifi-icon6lo {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon6lo {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon6lo {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery6lo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side6lo,
.statusbar6lo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side6lo {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar6lo {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--system-background-dark-base-primary);
  font-family: var(--font-gilroy-regular);
}
.login-warninglo {
  width: 100%;
  position: relative;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-700);
  font-family: var(--font-kalameh);
}
body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
