body{
    background-color:red;
}

.pku{
    display: block;
}

@media (max-width:1580px) {
    .pku{
        height: 100vh;
    }
    
}


.pku3{
    display: block;
}

@media (max-width:1580px) {
    .pku3{
        height: 100%;
    }
    
}



.pku1{
    display: block;

}



@media (max-width:1580px) {
    .pku1{
        height: 70vh;
    }

    
}

@media (max-width:700px) {
    .pku1{
        height: 100vh;
    }

    
}




.pku2{
    display: block;
}

@media (max-width:1580px) {
    .pku2{
        height: 100vh;
    }
    
}


.pku4{
    display: block;
}

@media (max-width:1580px) {
    .pku4{
        height: 100vh;
    }
    
}

.pku5{
    display: block;
}

@media (max-width:1580px) {
    .pku5{
        height: 180vh;
    }
    
}