.avatar-icondf,
.png-icondf {
  overflow: hidden;
  object-fit: cover;
}
.png-icondf {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
}
.avatar-icondf {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  z-index: 1;
}
.buttondf,
.png-icon1df {
  position: relative;
  border-radius: var(--br-81xl);
  overflow: hidden;
  flex-shrink: 0;
}
.buttondf {
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  box-shadow: var(--buttondrop-shadow);
  width: 52px;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.png-icon1df {
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.textdf {
  position: relative;
  font-weight: 600;
}
.button1df,
.png-parentdf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.png-parentdf {
  gap: var(--gap-9xs);
}
.button1df {
  flex: 1;
  border-radius: var(--br-81xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  box-shadow: var(--buttondrop-shadow);
  backdrop-filter: var(--products-no-drop-shadow);
  height: 52px;
  overflow: hidden;
  padding: var(--padding-9xs);
  box-sizing: border-box;
}
.divdf {
  position: relative;
  font-weight: 600;
  opacity: 0.8;
}
.button3df,
.button4df,
.header-footerdf {
  border-radius: var(--br-81xl);
  display: flex;
  align-items: center;
  justify-content: center;
}
.button4df {
  margin: 0 !important;
  position: absolute;
  top: 5px;
  left: 27px;
  background-color: var(--colors-red);
  width: 20px;
  height: 20px;
  flex-direction: column;
  z-index: 1;
}
.button3df,
.header-footerdf {
  box-shadow: var(--buttondrop-shadow);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  box-sizing: border-box;
}
.button3df {
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  width: 52px;
  height: 52px;
  padding: var(--padding-6xs);
  position: relative;
  font-size: var(--text-15-size);
}
.header-footerdf {
  cursor: pointer;
  border: solid 1px rgba(109, 107, 107, 0.671);
  align-self: stretch;
  background-color: var(--primary-page-color);
  backdrop-filter: blur(50px);
  height: 68px;
  padding: var(--padding-5xs);
  gap: var(--gap-5xs);
}
.png-icon4df,
.sortingdf {
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
}
.png-icon4df {
  position: relative;
  width: 44px;
  object-fit: cover;
}
.sortingdf {
  border-radius: var(--br-81xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  box-shadow: var(--buttondrop-shadow);
  backdrop-filter: var(--products-no-drop-shadow);
  width: 110px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.bdf {
  position: relative;
  letter-spacing: 0.6em;
}
.arrangingdf,
.button5df {
  box-shadow: var(--buttondrop-shadow);
  backdrop-filter: var(--products-no-drop-shadow);
  display: flex;
  flex-direction: column;
}
.button5df {
  border-radius: var(--br-81xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-12xs) var(--padding-7xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.arrangingdf {
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--hp-main-buttom-size);
}
.sorting-parentdf,
.titlesdf {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sorting-parentdf {
  justify-content: center;
  gap: var(--gap-9xs);
  font-size: var(--text-17-size);
}
.titlesdf {
  align-self: stretch;
  height: 44px;
  justify-content: space-between;
}
.text3df {
  font-size: 16px;
  position: relative;
  line-height:20px;
  font-weight: 600;
  margin-top: -8px;
}
.png-icon5df {
  align-self: stretch;
  border-radius: var(--br-base);
  max-width: 100%;
  overflow: hidden;
  height: 126.5px;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 0;
}
.kdf,
.png-icon5df,
.png-icon6df {
  position: relative;
}
.png-icon6df {
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.kdf {
  letter-spacing: 0.2em;
  font-weight: 800;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  -webkit-text-stroke: 0.5px rgba(255, 255, 255, 0.32);
}
.lottery-rewards1df {
  border-radius: var(--br-base);
  background-color: var(--color-gray-100);
  box-shadow: 0-15px 20px rgba(255, 255, 255, 0.5),
    0 15px 20px rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.spandf {
  font-size: var(--font-size-6xl);
}
.winnersdf {
  font-size: var(--font-size-3xs);
}
.split-among-10-winnersdf {
  font-weight: 600;
}
.split-among-10-containerdf {
  position: relative;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.32);
}
.lottery-rewards2df {
  border-radius: var(--br-base);
  background-color: var(--color-gray-100);
  box-shadow: 0-15px 20px rgba(255, 255, 255, 0.5),
    0 15px 20px rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-3xs);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-3xs);
}
.lottery-rewardsdf {
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 46.75px);
  left: calc(50% - 79px);
  height: 94px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  z-index: 1;
}
.frame-divdf,
.titledf {
  align-self: stretch;
  position: relative;
  align-items: center;
  justify-content: center;
}
.frame-divdf {
  height: 126.5px;
  display: flex;
  flex-direction: column;
  font-size: var(--lottery-award-size);
}
.titledf {
  flex: 1;
  font-weight: 600;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.text-boxdf,
.text-box-parentdf {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.text-boxdf {
  flex: 1;
  border-radius: var(--br-81xl);
  background: linear-gradient(rgba(0, 0, 0, 0.336), rgba(0, 0, 0, 0.356));
  box-shadow: var(--text-field);
  height: 28px;
  overflow: hidden;
  padding: var(--padding-xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
}
.text-box-parentdf {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--text-13-size);
  color: var(--text-primary);
}
.button6df,
.png-icon7df {
  overflow: hidden;
  flex-shrink: 0;
}
.png-icon7df {
  position: relative;
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.button6df {
  border-top: solid 1px rgba(173, 169, 169, 0.897);
  align-self: stretch;
  border-radius: var(--br-81xl);
  background: rgba(148, 147, 147, 0.3);
  background-position-x: right;
  box-shadow: var(--buttondrop-shadow);
  backdrop-filter: var(--products-no-drop-shadow);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  font-size: var(--text-17-size);
}
.lottery-itemsdf,
.lottery-rewards3df {
  border-radius: var(--br-base);
  display: flex;
  align-items: center;
  gap: var(--gap-5xs);
}
.lottery-itemsdf {
  background-color: rgba(140, 172, 185, 0.205);
  align-self: stretch;
  backdrop-filter: var(--products-no-drop-shadow);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-base);
  justify-content: center;
}
.lottery-rewards3df {
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 26.25px);
  left: calc(50% - 78px);
  background-color: var(--color-gray-100);
  box-shadow: 0-15px 15px rgba(255, 255, 255, 0.5),
    0 15px 15px rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  height: 52px;
  flex-direction: row;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  justify-content: flex-start;
  z-index: 1;
}
.title-txtdf {
  line-break: anywhere;
}
.title3df {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lottery-items1df,
.lottery-pages-vertical-slidingdf {
  align-self: stretch;
  border-radius: var(--br-base);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.lottery-items1df {
  border-left: solid 1px rgba(128, 127, 127, 0.815);
  border-top: solid 1px rgba(128, 127, 127, 0.815);
  background-color: rgba(102, 101, 101, 0);
  backdrop-filter: blur(25px);
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-base);
  align-items: center;
  gap: var(--gap-5xs);
}
.lottery-pages-vertical-slidingdf {
  flex: 1;
  overflow-y: auto;
  align-items: flex-start;
  gap: var(--gap-base);
  font-size: var(--text-15-size);
}
.text9df {
  position: relative;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.61);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.segment-control1df {
  flex: 1;
  overflow: hidden;
  padding: var(--padding-5xs);
  min-width: 72px;
}
.segment-controldf,
.segment-control1df,
.segment-control2df {
  align-self: stretch;
  border-radius: var(--br-81xl);
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.segment-control2df {
  flex: 1;
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  overflow: hidden;
  padding: var(--padding-5xs);
  min-width: 72px;
}
.segment-controldf {
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.336), rgba(0, 0, 0, 0.356));
  box-shadow: var(--text-field);
  box-shadow: var(--text-field);
  height: 44px;
  overflow-x: auto;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
  font-size: var(--text-13-size);
}
.png-icon14df,
.primary-platesdf {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
}
.primary-platesdf {
  width: 90%;
  margin: 0 auto;
  height: 79%;
  margin-top:97px;
  margin-right: 1px;
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}

.header-footer1df,
.lotteryactivedf {
  position: relative;
  display: flex;
  box-sizing: border-box;
  align-items: center;
}

.png-icon8df {
  align-self: stretch;
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.button5df {
  border: solid 1px rgba(88, 88, 88, 0.918);
  box-shadow: rgb(0, 0, 0);
  border-radius: var(--br-81xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  box-shadow: var(--buttondrop-shadow);
  width: 52px;
  height: 52px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.button5df,
.button7df,
.png-icon10df {
  overflow: hidden;
  flex-shrink: 0;
}
.button7df {
  border: solid 1px rgba(88, 88, 88, 0.918);
  box-shadow: rgb(0, 0, 0);
  border-radius: var(--br-81xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);

  width: 52px;
  height: 52px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.png-icon10df {
  margin: 0 !important;

  width: 89px;
  height: 62px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
}
.header-footer1df,
.iphone-14homedf {
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
}
.header-footer1df {
  border-top: solid 2px rgba(148, 146, 146, 0.671);
  border-bottom: solid 1px rgba(68, 67, 67, 0.966);
  align-self: stretch;
  border-radius: var(--br-81xl);
  background-color: var(--primary-page-color);
  box-shadow: var(--buttondrop-shadow);
  backdrop-filter: blur(50px);
  height: 68px;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
}

.lotteryactivedf {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-semibold);
}

body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/image-5@2x.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 375px) {
  .lotteryactivedf {
    height: 100vh;
  }
  .primary-platesdf {
    margin-top: 80px;
  }
}
@media (max-width: 360px) {

  .primary-platesdf {
    margin-top: 90px;
  }
}

@media (max-width: 344px) {
  .lotteryactivedf {
    height: 720px;
  }
}
