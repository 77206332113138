.image-5-icon28 {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.swatch127 {
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  position: absolute;
  height: 79%;
  width: 90%;
  top: 12%;
  right: 5.15%;
  bottom: 5.91%;
  left: 4.90%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
}
.div125 {
  width: 8px;
  display: none;
}
.div125,
.label35 {
  position: relative;
  line-height: 20px;
  background: linear-gradient(#969696, #b8b8b8), rgba(255, 255, 255, 0.425);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-15 {
  flex: 1;
  border-radius: var(--br-81xl);
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-10xs);
}
.div126 {
  width: 8px;
  display: none;
}
.div126,
.label36 {
  position: relative;
  line-height: 20px;
  background: linear-gradient(#969696, #b8b8b8), rgba(255, 255, 255, 0.425);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-25 {
  flex: 1;
  border-radius: var(--br-81xl);
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-10xs);
}
.div127,
.label37 {
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-35 {
  flex: 1;
  box-shadow: var(--blur-shadow-small);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(122, 171, 216, 0.281);
  box-sizing: border-box;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-10xs);
}
.div128 {
  width: 8px;
  display: none;
}
.div128,
.label38 {
  position: relative;
  line-height: 20px;
  background: linear-gradient(#969696, #b8b8b8), rgba(255, 255, 255, 0.425);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-4,
.segmented-control5 {
  border-radius: var(--br-81xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.button-4 {
  flex: 1;
  height: 36px;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-10xs);
}
.segmented-control5 {
  position: relative;
  top: 640px;
  width: 85%;
  margin: 0 auto;
  border-radius: var(--br-81xl);
  background: rgba(12, 12, 12, 0.205);
  height: 44px;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
  font-size: var(--font-size-mini);
  color: var(--text-primary);
}
.profile5 {
  position: absolute;
  top: 65px;
  left: calc(50% - 27.5px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-100);
}
.swatch128 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: 0.8px solid var(--color-gray-800);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.person-icon29 {
  position: absolute;
  height: 53.85%;
  width: 53.85%;
  top: 23.08%;
  right: 23.08%;
  bottom: 23.08%;
  left: 23.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent76 {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: 6.16%;
  right: 5.33%;
  bottom: 87.44%;
  left: 80.8%;
}
.swatch129 {
  height: 30px;
  width: 100%;
  margin: 0 auto;
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.3);
}
.exam-result {
  margin-top: -30px;
  text-align: center;
  line-height: 30px;
}
.swatch-parent77 {
  position: absolute;
  height: 3.45%;
  width: 85%;
  top: 13.40%;
  right: 7.73%;
  bottom: 79.8%;
  left: 7.73%;
  font-size: var(--font-size-mid);
}
.swatch130 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: 0.8px solid var(--color-gray-800);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.arrowbackward-icon19 {
  position: absolute;
  height: 53.85%;
  width: 53.85%;
  top: 23.08%;
  right: 23.08%;
  bottom: 23.08%;
  left: 23.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent78,
.swatch131 {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: 6.16%;
  right: 79.73%;
  bottom: 87.44%;
  left: 6.4%;
}
.swatch131 {
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: 0.8px solid var(--color-gray-800);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.person-icon30 {
  position: absolute;
  height: 53.85%;
  width: 53.85%;
  top: 23.08%;
  right: 23.08%;
  bottom: 23.08%;
  left: 23.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent79 {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: -27.09%;
  right: 116%;
  bottom: 120.69%;
  left: -29.87%;
}
.div129 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
}
.edit-button61 {
  width: 22px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 22px;
}
.edit-button60 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  font-size: var(--font-size-smi);
}
.app-icons31 {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.image31 {
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.test1 {
  margin: 0;
  margin-top: 10px;
}
.cbct-scan1 {
  margin: 0;
  font-size: var(--font-size-smi);
  font-family: var(--font-gilroy-medium);
  color:var(--color-gray-200);
}
.disclosure11,
.title31 {
  position: relative;
  line-height: 22px;
}
.title31 {
  align-self: stretch;
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-base);
  color: var(--text-primary);
  font-family: var(--font-gilroy-semibold);
}
.disclosure11 {
  width: 11px;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 18.2px;
  flex-shrink: 0;
}
.disclosure11,
.grabber63,
.trailing-accessories31 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trailing-accessories31 {
  height: 44px;
  flex-direction: row;
  font-family: var(--font-gilroy-medium);
}
.grabber63 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(-10% - 11px);
  line-height: 22px;
  font-weight: 600;
  color: white;
  width: 22px;
  height: 22px;
}
.grabber62 {
  width: 32px;
  position: relative;
  height: 32px;
  display: block;
}
.div130,
.row-inset31 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-inset31 {
  width: 100%;
  position: relative;
  border-radius: 10px;
  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 66px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 0;
  font-size: var(--font-size-mid);
  color: var(--grays-white-dark);
}
.div130 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  width: 22px;
  height: 22px;
}
.edit-button63 {
  width: 22px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 22px;
}
.edit-button62 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  font-size: var(--font-size-smi);
}
.app-icons32 {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.image32 {
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.test-1 {
  margin: 0;
  margin-top: 10px;
}
.periapical-x-ray1 {
  margin: 0;
  font-size: var(--font-size-smi);
  font-family: var(--font-gilroy-medium);
  color: var(--color-gray-200);
}
.disclosure12,
.title32 {
  position: relative;
  line-height: 22px;
  display: flex;
}
.title32 {
  align-self: stretch;
  flex: 1;
  text-align: left;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-base);
  color: var(--text-primary);
  font-family: var(--font-gilroy-semibold);
}
.disclosure12 {
  width: 11px;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 18.2px;
  flex-shrink: 0;
}
.disclosure12,
.grabber65,
.trailing-accessories32 {
  align-items: center;
  justify-content: center;
}
.trailing-accessories32 {
  height: 44px;
  display: none;
  flex-direction: row;
  font-family: var(--font-gilroy-medium);
}
.grabber65 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  width: 22px;
  height: 22px;
}
.grabber64 {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
}
.div131,
.row-inset32 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-inset32 {
  width: 100%;
  position: relative;
  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 66px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 0;
  font-size: var(--font-size-mid);
  color: var(--grays-white-dark);
}
.div131 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  width: 22px;
  height: 22px;
}
.edit-button65 {
  width: 22px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 22px;
}
.edit-button64 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  color: var(--grays-white-dark);
}
.app-icons33 {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.image33 {
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.test-2 {
  margin: 0;
  margin-top: 10px;
}
.panoramic-x-ray1 {
  margin: 0;
  font-size: var(--font-size-smi);
  font-family: var(--font-gilroy-medium);
  color: var(--color-gray-200);
}
.checkmark20,
.title33 {
  position: relative;
  line-height: 22px;
}
.title33 {
  align-self: stretch;
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-base);
  font-family: var(--font-gilroy-semibold);
}
.checkmark20 {
  margin-left: 5px;
  width: 18px;
  height: 22px;
  flex-shrink: 0;
}
.checkmark20,
.grabber67,
.trailing-accessories33 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trailing-accessories33 {
  height: 44px;
  flex-direction: row;
  font-size: var(--font-size-lgi);
  font-family: var(--font-gilroy-medium);
}
.grabber67 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 22px;
  height: 22px;
}
.grabber66 {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
  font-size: var(--font-size-mid);
}
.div132,
.row-inset33 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-inset33 {
  width: 100%;
  position: relative;
  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 66px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 0;
  font-size: var(--font-size-mid);
  color: var(--grays-white-dark);
}
.div132 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  width: 22px;
  height: 22px;
}
.edit-button67 {
  width: 22px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 22px;
}
.edit-button66 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  color: var(--grays-white-dark);
}
.app-icons34 {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.image34 {
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.test-3 {
  margin: 0;
  margin-top: 10px;
}
.panoramic-x-ray2 {
  margin: 0;
  font-size: var(--font-size-smi);
  font-family: var(--font-gilroy-medium);
  color: var(--color-gray-200);
}
.checkmark21,
.title34 {
  position: relative;
  line-height: 22px;
}
.title34 {
  align-self: stretch;
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-base);
  font-family: var(--font-gilroy-semibold);
}
.checkmark21 {
  width: 18px;
  height: 22px;
  flex-shrink: 0;
}
.checkmark21,
.grabber69,
.trailing-accessories34 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trailing-accessories34 {
  height: 44px;
  flex-direction: row;
  font-size: var(--font-size-lgi);
  font-family: var(--font-gilroy-medium);
}
.grabber69 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 22px;
  height: 22px;
}
.grabber68 {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
  font-size: var(--font-size-mid);
}
.div133,
.row-inset34 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-inset34 {
  width: 100%;
  position: relative;
  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 66px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 0;
  font-size: var(--font-size-mid);
  color: var(--grays-white-dark);
}
.div133 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  width: 22px;
  height: 22px;
}
.edit-button69 {
  width: 22px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 22px;
}
.edit-button68 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  font-size: var(--font-size-smi);
  color: var(--grays-white-dark);
}
.app-icons35 {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.image35 {
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.test-4 {
  margin: 0;
  margin-top: 10px;
}
.cbct-scan2 {
  margin: 0;
  font-size: var(--font-size-smi);
  font-family: var(--font-gilroy-medium);
  color: var(--color-gray-200);
}
.checkmark22,
.title35 {
  position: relative;
  line-height: 22px;
}
.title35 {
  align-self: stretch;
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-gilroy-semibold);
}
.checkmark22 {
  width: 18px;
  height: 22px;
  flex-shrink: 0;
}
.checkmark22,
.grabber71,
.trailing-accessories35 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trailing-accessories35 {
  height: 44px;
  flex-direction: row;
  font-family: var(--font-gilroy-medium);
}
.grabber71 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 22px;
  height: 22px;
}

.grabber70 {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
  font-size: var(--font-size-mid);
}
.div134,
.row-inset35 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-inset35 {
  width: 100%;
  position: relative;
  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 66px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 0;
  font-size: var(--font-size-mid);
  color: var(--grays-white-dark);
}
.div134 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  width: 22px;
  height: 22px;
}
.edit-button71 {
  width: 22px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 22px;
}
.edit-button70 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  font-size: var(--font-size-smi);
  color: var(--grays-white-dark);
}
.app-icons36 {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.image36 {
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.test-5 {
  margin: 0;
  margin-top: 10px;
}
.cbct-scan3 {
  margin: 0;
  font-size: var(--font-size-smi);
  font-family: var(--font-gilroy-medium);
  color: var(--color-gray-200);
}
.checkmark23,
.title36 {
  position: relative;
  line-height: 22px;
}
.title36 {
  align-self: stretch;
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-gilroy-semibold);
}
.checkmark23 {
  width: 18px;
  height: 22px;
  flex-shrink: 0;
}
.checkmark23,
.grabber73,
.trailing-accessories36 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trailing-accessories36 {
  height: 44px;
  flex-direction: row;
  font-family: var(--font-gilroy-medium);
}
.grabber73 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 22px;
  height: 22px;
}
.grabber72 {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
  font-size: var(--font-size-mid);
}
.div135,
.row-inset36 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-inset36 {
  width: 100%;
  position: relative;

  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 66px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 0;
  font-size: var(--font-size-mid);
  color: var(--grays-white-dark);
}
.div135 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  width: 22px;
  height: 22px;
}
.edit-button73 {
  width: 22px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 22px;
}
.edit-button72 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  font-size: var(--font-size-smi);
}
.app-icons37 {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.image37 {
  border-radius: var(--br-81xl);
  background-color: var(--colors-blue);
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.test-6 {
  margin: 0;
  margin-top: 10px;
}
.cbct-scan4 {
  margin: 0;
  font-size: var(--font-size-smi);
  font-family: var(--font-gilroy-medium);
  color: var(--color-gray-200);
}
.disclosure13,
.title37 {
  position: relative;
  line-height: 22px;
}
.title37 {
  align-self: stretch;
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-base);
  color: var(--text-primary);
  font-family: var(--font-gilroy-semibold);
}
.disclosure13 {
  width: 11px;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 18.2px;
  flex-shrink: 0;
}
.disclosure13,
.grabber75,
.trailing-accessories37 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trailing-accessories37 {
  height: 44px;
  flex-direction: row;
  font-family: var(--font-gilroy-medium);
}
.grabber75 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  line-height: 22px;
  font-weight: 600;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 22px;
  height: 22px;
}
.grabber74 {
  width: 32px;
  position: relative;
  height: 32px;
  display: none;
}
.row-inset37 {
  border-radius: 0 0 var(--br-base) var(--br-base);
  width: 100%;
  position: relative;
  background: rgba(12, 12, 12, 0.411);
  border-bottom: 1px solid var(--separators-separator);
  box-sizing: border-box;
  height: 66px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
  z-index: 0;
  font-size: var(--font-size-mid);
  color: var(--grays-white-dark);
}
.vuesaxoutlinearrow-right-icon27 {
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 21px;
  left: 251px;
  height: 24px;
  z-index: 7;
}
.vuesaxoutlinearrow-right-icon28,
.vuesaxoutlinearrow-right-icon29,
.vuesaxoutlinearrow-right-icon30 {
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 87px;
  left: 251px;
  height: 24px;
  z-index: 8;
}
.vuesaxoutlinearrow-right-icon29,
.vuesaxoutlinearrow-right-icon30 {
  top: 152px;
  z-index: 9;
}
.vuesaxoutlinearrow-right-icon30 {
  top: 217px;
  z-index: 10;
}
.vuesaxoutlinearrow-right-icon31,
.vuesaxoutlinearrow-right-icon32,
.vuesaxoutlinearrow-right-icon33 {
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 282px;
  left: 251px;
  height: 24px;
  z-index: 11;
}
.vuesaxoutlinearrow-right-icon32,
.vuesaxoutlinearrow-right-icon33 {
  top: 347px;
  z-index: 12;
}
.vuesaxoutlinearrow-right-icon33 {
  top: 413px;
  z-index: 13;
}
.swatch132,
.swatch133,
.swatch134,
.swatch135,
.swatch136,
.swatch137,
.swatch138 {
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: 83px;
  left: 247px;

  height: 85px;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.swatch133,
.swatch134,
.swatch135,
.swatch136,
.swatch137,
.swatch138 {
  top: 17px;
  z-index: 15;
}
.swatch134,
.swatch135,
.swatch136,
.swatch137,
.swatch138 {
  top: 148px;
  z-index: 16;
}
.swatch135,
.swatch136,
.swatch137,
.swatch138 {
  top: 213px;
  z-index: 17;
}
.swatch136,
.swatch137,
.swatch138 {
  top: 278px;
  z-index: 18;
}
.swatch137,
.swatch138 {
  top: 343px;
  z-index: 19;
}
.swatch138 {
  top: 409px;
  z-index: 20;
}
.div136,
.score7 {
  position: absolute;
  top: 5px;
  left: 17px;
  line-height: 22px;
}
.score7 {
  left: 45px;
  font-size: var(--font-size-3xs);
  font-family: var(--font-gilroy-medium);
  line-height: 22px;
}
.parent2 {
  width: 85px;
  position: absolute;
  margin: 0 !important;

  height: 32px;
  z-index: 21;
  text-align: left;
  font-family: var(--font-gilroy-bold);
  box-shadow: var(--recessed);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(7, 7, 7, 0), rgba(7, 7, 7, 0)),
    rgb(22, 22, 22);
}
.div137,
.score8 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 22px;
}
.score8 {
  left: 31px;
  font-size: var(--font-size-3xs);
  font-family: var(--font-gilroy-medium);
}
.parent3 {
  width: 58px;
  position: absolute;
  margin: 0 !important;
  top: 88px;
  left: 177px;
  height: 22px;
  z-index: 22;
  text-align: left;
  font-family: var(--font-gilroy-bold);
}
.div138,
.score9 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 22px;
}
.score9 {
  left: 31px;
  font-size: var(--font-size-3xs);
  font-family: var(--font-gilroy-medium);
}
.parent4 {
  width: 58px;
  position: absolute;
  margin: 0 !important;
  top: 153px;
  left: 177px;
  height: 22px;
  z-index: 23;
  text-align: left;
  font-family: var(--font-gilroy-bold);
}
.div139,
.score10 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 22px;
}
.score10 {
  left: 32px;
  font-size: var(--font-size-3xs);
  font-family: var(--font-gilroy-medium);
}
.parent5 {
  width: 59px;
  position: absolute;
  margin: 0 !important;
  top: 218px;
  left: 176px;
  height: 22px;
  z-index: 24;
  text-align: left;
  font-family: var(--font-gilroy-bold);
}
.div140,
.score11 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 22px;
}
.score11 {
  left: 31px;
  font-size: var(--font-size-3xs);
  font-family: var(--font-gilroy-medium);
}
.parent6 {
  width: 58px;
  position: absolute;
  margin: 0 !important;
  top: 283px;
  left: 177px;
  height: 22px;
  z-index: 25;
  text-align: left;
  font-family: var(--font-gilroy-bold);
}
.div141,
.score12 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 22px;
}
.score12 {
  left: 31px;
  font-size: var(--font-size-3xs);
  font-family: var(--font-gilroy-medium);
}
.parent7 {
  width: 58px;
  position: absolute;
  margin: 0 !important;
  top: 348px;
  left: 177px;
  height: 22px;
  z-index: 26;
  text-align: left;
  font-family: var(--font-gilroy-bold);
}
.div142,
.score13 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 22px;
}
.score13 {
  left: 31px;
  font-size: var(--font-size-3xs);
  font-family: var(--font-gilroy-medium);
}
.parent8 {
  width: 58px;
  position: absolute;
  margin: 0 !important;
  top: 414px;
  left: 177px;
  height: 22px;
  z-index: 27;
  text-align: left;
  font-family: var(--font-gilroy-bold);
}
.section-13 {
  position: relative;
  top: calc(26% - 222px);
  width: 85%;
  padding: 5px;
  overflow-x: auto;
  opacity: 60%;
  width: 85%;
  margin: 0 auto;
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1px;
}
.slider-default16 {
  position: absolute;
  top: 184px;
  left: 335px;
  border-radius: var(--br-81xl);
  width: 12px;
  height: 460px;
  overflow: hidden;
  object-fit: contain;
}
.time30 {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time28 {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side28 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera28 {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera28,
.statusbar-dynamicisland28,
.truedepth-camera28 {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera28 {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland28 {
  width: 125px;
  position: relative;
}
.dynamic-island28 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal28,
.wifi-icon28 {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon28 {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon28 {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery28 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side28,
.statusbar28 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side28 {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar28 {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  color: var(--system-background-dark-base-primary);
  font-family: var(--font-gilroy-regular);
}
.exam {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-semibold);
}
body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.ppp {
  height: 450px;
  width: 100%;
  overflow-y: scroll;
}
@media (max-width: 375px) {
  .exam {
    height: 100vh;
  }
}
@media (max-width: 375px) {
  .ppp {
    height: 350px;
    width: 100%;
    margin-top: 60px;
  }
  .swatch-parent77 {
    position: absolute;
    height: 3.45%;
    width: 85%;
    top: 13.50%;
    right: 7.73%;
    bottom: 79.8%;
    left: 7.73%;
    font-size: var(--font-size-mid);
  }
}

@media (max-width: 375px) {
  .segmented-control5 {
    position: absolute;
    top: 518px;
    right: 25px;
  }
  .label35,.label36,.label37,.label38{
    font-size: 12px;
  }
  
}

@media (max-width: 360px) {
  .ppp {
    height: 400px;
    width: 100%;
    margin-top: 50px;
  }
}
@media (max-width: 360px) {
  .segmented-control5 {
    position: absolute;
    top: 587px;
    right: 25px;
  }
}

@media (max-width: 344px) {
  .ppp {
    height: 500px;
    width: 100%;
    margin-top: 60px;
  }
}

@media (max-width: 344px) {
  .segmented-control5 {
    position: absolute;
    top: 715px;
    right: 25px;
  }
}
