.modalR {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-contentR {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
  }
  .modal-footerR {
    display: flex;
    justify-content: center; /* برای وسط چین کردن */
    gap: 10px; /* فاصله بین دکمه‌ها */
    padding: 15px; /* تنظیم پدینگ برای زیبایی */
  }