.difficulty-labelr {
    display: flex;
    flex-direction: row;  /* نمایش نوشته‌ها به صورت افقی */
    align-items: center;  /* تراز کردن نوشته‌ها در مرکز */
    gap: 10px;  /* فاصله بین نوشته‌ها */
  }
  
  .difficulty-textr {
    font-size: 12px;
    font-weight: bold;
    color: #333;
  }
  
  .images {
    display: flex;
    flex-direction: row;  /* نمایش تصاویر به صورت افقی */
    align-items: center;  /* تراز کردن تصاویر در مرکز */
    gap: 10px;  /* فاصله بین تصاویر */
  }
  
  .images img {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
  