body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-gilroy-regular: Gilroy-Regular;
  --font-gilroy-semibold: Gilroy-SemiBold;
  --font-kalameh: Kalameh;
  --font-inter: Inter;
  --font-gilroy-bold: Gilroy-Bold;
  --font-sf-pro: "SF Pro";
  --font-gilroy-medium: Gilroy-Medium;
  --fonts-en-12-regular: Roboto;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-sm: 14px;
  --fonts-en-12-regular-size: 12px;
  --font-size-xl: 20px;
  --font-size-mid: 17px;
  --font-size-smi: 13px;
  --font-size-lgi: 19px;
  --font-size-5xl: 24px;
  --font-size-lg: 18px;

  /* Colors */
  --grays-white-dark: #fff;
  --system-background-dark-base-primary: #000;
  --color-gray-300: #1e1e1e;
  --color-gray-500: rgba(0, 0, 0, 0.4);
  --color-gray-600: rgba(255, 255, 255, 0.9);
  --color-gray-400: rgba(255, 255, 255, 0.3);
  --color-gray-700: rgba(255, 255, 255, 0.23);
  --text-primary: rgba(255, 255, 255, 0.96);
  --color-gray-800: rgba(32, 32, 32, 0.96);
  --windows-stroke-glass-specular: rgba(255, 255, 255, 0.4);
  --color-gray-100: rgba(255, 255, 255, 0.6);
  --windows-glass: rgba(190, 190, 190, 0.075);
  --color-gray-200: rgba(255, 255, 255, 0.22);
  --color-darkslategray: #303030;
  --colors-blue: #0a84ff;
  --separators-separator: rgba(94, 94, 94, 0.15);
  --color-red: #e20d0d;

  /* Gaps */
  --gap-5xs: 8px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;

  /* Paddings */
  --padding-2xs: 11px;
  --padding-10xs: 3px;
  --padding-3xs: 10px;
  --padding-xl: 20px;
  --padding-5xs: 8px;
  --padding-9xs: 4px;
  --padding-11xs: 2px;
  --padding-xs: 12px;

  /* Border radiuses */
  --br-5xl: 24px;
  --br-81xl: 100px;
  --br-xs: 12px;
  --br-base: 16px;
  --br-13xl: 32px;
  --br-xl: 20px;
  --br-481xl: 500px;
  --br-lgi: 19px;
  --br-3xl: 22px;

  /* Effects */
  --btn-rmazani: 0px 1px 1px rgba(255, 255, 255, 0.22) inset,
    0px -1px 1px rgba(0, 0, 0, 0.08) inset, 0px 1px 1px rgba(0, 0, 0, 0.22);
  --recessed: 0px -0.5px 1px rgba(255, 255, 255, 0.3) inset,
    0px -0.5px 1px rgba(255, 255, 255, 0.25) inset,
    0px 1.5px 4px rgba(0, 0, 0, 0.08) inset,
    0px 1.5px 4px rgba(0, 0, 0, 0.1) inset;
  --blur-shadow-small: 0px 2px 4px rgba(0, 0, 0, 0.1);
}


body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --text-13: Inter;

  /* font sizes */
  --text-13-size: 13px;
  --text-17-size: 17px;
  --lottery-award-size: 30px;
  --text-15-size: 15px;
  --font-size-3xs: 10px;
  --font-size-6xl: 25px;
  --hp-main-buttom-size: 20px;
  --text-19-size: 19px;

  /* Colors */
  --color-gray-100: rgba(255, 255, 255, 0.01);
  --text-primary: rgba(255, 255, 255, 0.96);
  --primary-page-color: rgba(128, 128, 128, 0.3);
  --color-white: #fff;
  --colors-red: #ff453a;

  /* Gaps */
  --gap-base: 16px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-3xs: 10px;

  /* Paddings */
  --padding-29xl: 48px;
  --padding-base: 16px;
  --padding-5xs: 8px;
  --padding-9xs: 4px;
  --padding-xl: 20px;
  --padding-xs: 12px;
  --padding-11xs: 2px;
  --padding-3xs: 10px;
  --padding-12xs: 1px;
  --padding-7xs: 6px;
  --padding-6xs: 7px;

  /* border radiuses */
  --br-81xl: 100px;
  --br-base: 16px;

  /* Effects */
  --products-no-drop-shadow: blur(100px);
  --text-field: 0px -0.5px 1px rgba(255, 255, 255, 0.3) inset,
    0px -0.5px 1px rgba(255, 255, 255, 0.25) inset,
    1px 1.5px 4px rgba(0, 0, 0, 0.08) inset,
    1px 1.5px 4px rgba(0, 0, 0, 0.1) inset;
  --buttondrop-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
