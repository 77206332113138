 @font-face {
    font-family: "Yekan";
    src: url("../../src/fonts/vazir.ttf");
  }
.pok{
    font-family: Yekan;
    font-weight: normal;
    font-size: 15px;
}
.bac{
  background-image: none;
}