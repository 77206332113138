.image-5-icon13K {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.swatch41K {
  position: absolute;
  height: 75.37%;
  width: 89.33%;
  top: 14.29%;
  right: 5.35%;
  bottom: 10.34%;
  left: 5.32%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  border: 1.4px solid var(--windows-stroke-glass-specular);
  box-sizing: border-box;
}
.swatch42K {
  height: 32.71%;
  width: 7.84%;
  top: 32.88%;
  right: 82.55%;
  bottom: 34.41%;
  left: 9.61%;
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.3);
}
.swatch42K,
.swatch43K,
.swatch44K {
  position: absolute;
  border-radius: var(--br-xl);
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.swatch43K {
  height: 32.71%;
  width: 7.84%;
  top: 66.01%;
  right: 82.55%;
  bottom: 1.28%;
  left: 9.61%;
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.3);
}
.swatch44K {
  height: 18.76%;
  width: 45.1%;
  top: 41.26%;
  right: -13.36%;
  bottom: 39.99%;
  left: 68.26%;
  background: linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    rgba(214, 214, 214, 0.45);
}
.div27K {
  position: absolute;
  top: 404px;
  left: 127px;
  font-size: 80px;
  line-height: 32px;
  font-family: var(--font-gilroy-bold);
  display: inline-block;
  width: 89px;
  height: 44px;
}
.exam-mandible4K {
  top: 65px;
  left: calc(50% - 71.5px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  color: var(--color-gray-300);
}
.exam-mandible4K,
.question-10K,
.the-test-wasK {
  position: absolute;
  font-family: var(--font-gilroy-bold);
}
.question-10K {
  top: 136px;
  left: 36px;
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  color: var(--text-primary);
  text-align: left;
}
.the-test-wasK {
  top: 177px;
  left: 42px;
  font-size: var(--font-size-5xl);
  line-height: 32px;
  display: inline-block;
  width: 267px;
}
.button-text27K,
.sign-in-btn27K {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-text27K {
  width: 66px;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  height: 36px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.sign-in-btn27K {
  position: absolute;
  top: 129px;
  left: calc(50% + 57.5px);
  box-shadow: var(--btn-rmazani);
  color: var(--text-primary);
}
.scoreK {
  position: absolute;
  top: 270px;
  left: 153px;
}
.falseK,
.resultK,
.scoreK {
  line-height: 22px;
}
.resultK {
  position: absolute;
  top: 539px;
  left: 144px;
}
.falseK {
  position: relative;
}
.button-text28K {
  width: 279px;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(255, 0, 0, 0.9), rgba(255, 0, 0, 0.9)),
    rgba(255, 255, 255, 0.06);
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
}
.button-text28K,
.button-text29K,
.sign-in-btn28K,
.sign-in-btn29K {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sign-in-btn28K {
  position: absolute;
  top: 604px;
  left: calc(50% - 151.5px);
  box-shadow: var(--btn-rmazani);
  justify-content: flex-start;
}
.button-text29K,
.sign-in-btn29K {
  justify-content: center;
}
.button-text29K {
  width: 279px;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(19, 156, 16, 0.9), rgba(19, 156, 16, 0.9)),
    rgba(255, 255, 255, 0.06);
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
}
.sign-in-btn29K {
  position: absolute;
  top: 664px;
  left: calc(50% - 151.5px);
  box-shadow: var(--btn-rmazani);
}
.slider-default8K {
  position: absolute;
  top: 177px;
  left: 317px;
  border-radius: var(--br-81xl);
  width: 12px;
  height: 531px;
  overflow: hidden;
  object-fit: contain;
  opacity: 0.2;
}
.swatch45K {
  position: absolute;
  height: 95.19%;
  width: 105.05%;
  top: 0;
  right: -105.05%;
  bottom: 4.81%;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: 0.8px solid var(--color-gray-100);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.arrowbackward-icon8K {
  position: absolute;
  height: 53.85%;
  width: 53.94%;
  top: 23.08%;
  right: 23.03%;
  bottom: 23.08%;
  left: 23.03%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent17K,
.swatch-parent18K {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: 6.16%;
  right: 79.75%;
  bottom: 87.44%;
  left: 6.39%;
}
.swatch-parent18K {
  right: 5.32%;
  left: 80.81%;
}
.pop-up-exit-childK {
  position: absolute;
  top: 118px;
  left: 21px;
  border-radius: var(--br-lgi);
  background-color: rgba(0, 0, 0, 0.25);
  width: 334px;
  height: 609px;
}
.swatch47K {
  position: absolute;
  height: 33.41%;
  width: 30.81%;
  top: 44.21%;
  right: -16.41%;
  bottom: 22.38%;
  left: 85.6%;
  border-radius: var(--br-base);
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.07);
  transform: rotate(90deg);
  transform-origin: 0 0;
  display: none;
}
.swatch48K {
  position: relative;
  margin: 0 auto;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  border: 1.4px solid var(--windows-stroke-glass-specular);
  box-sizing: border-box;
  width: 85%;
  height: 154px;
}
.do-you-wantK {
  position: relative;
  margin: 0 auto;
  margin-top: -130px;
  font-size: var(--font-size-3xl);
  line-height: 32px;
  font-family: var(--font-gilroy-bold);

  text-align: center;
}
.button-text30K:hover {
  background: rgba(255, 255, 255, 0.171);
}
.button-text30K:focus {
  outline-color: transparent;
  outline-style: solid;
}
.button-text30K:active {
  background: rgba(255, 255, 255, 0.171);
  box-shadow: var(--recessed);
  
}
.button-text30K {
  width: 50%;
  margin: 0 auto;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    0 0;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  box-shadow: var(--btn-rmazani);
}
.button-text30K,
.button-text31K,
.sign-in-btn30K,
.sign-in-btn31K {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sign-in-btn30K {
  position: relative;
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: var(--br-481xl);

  justify-content: flex-start;
}

.button-text31K,
.sign-in-btn31K {
  justify-content: center;
}

.button-text31K {
  width: 50%;
  box-shadow: var(--btn-rmazani);
  margin: o auto;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.04);
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.button-text31K:hover {
  background: rgba(255, 255, 255, 0.171);
}
.button-text31K:focus {
  outline-color: transparent;
  outline-style: solid;
}
.button-text31K:active {
  background: rgba(255, 255, 255, 0.171);
  box-shadow: var(--recessed);
  
}
.sign-in-btn31K {
  position: absolute;
  top: 84px;
  left: calc(50% - 141.5px);
  box-shadow: var(--btn-rmazani);
}
.swatch-parent19K,
.time13K {
  position: absolute;
  top: 329px;
  width: 100%;
  height: 154px;
}
.time13K {
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time13K {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side13K {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera13K {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera13K,
.statusbar-dynamicisland13K,
.truedepth-camera13K {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera13K {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland13K {
  width: 125px;
  position: relative;
}
.dynamic-island13K {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal13K,
.wifi-icon13K {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon13K {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon13K {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery13K {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side13K,
.statusbar13K {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side13K {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar13K {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  font-size: var(--font-size-base);
  color: var(--system-background-dark-base-primary);
  font-family: var(--font-gilroy-regular);
}
