.image-5-icon22zx {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.swatch100zx,
.swatch99zx {
  position: relative;
  border-radius: var(--br-xl);
}
.po5{
  color: #1E1E1E;
  text-align: center;
  font-family: bold;
  line-height:40px;
  font-size: 16px;
}
.swatch99zx {
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  margin: 0 auto;
position: absolute;
top: 12%;
right: 5.1%;
bottom: 5.91%;
left: 4.90%;
  width: 90%;
  height: 79%;
}
.po2{
  text-align: left;
  color: white;
  font-size: 16px;
  font-family: bold;
}
.swatch100zx {
  height: 64%;
  position: relative;
  z-index: 99;
  margin: 0 auto;
  margin-top: 120px;
  background: rgba(12, 12, 12, 0.205);
  width: 85%;
  opacity: 50%;
}
.slider-default11zx {
  position: absolute;
  top: 204px;
  left: 323px;
  border-radius: var(--br-81xl);
  width: 12px;
  height: 420px;
  overflow: hidden;
  object-fit: contain;
}
.button-text2zx,
.sign-in-btn2zx {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sign-in-btn2zx {
  position: relative;
  margin: 0 auto;
  text-align: center;
  font-size: var(--font-size-mid);
  font-family: var(--font-gilroy-semibold);
}
.button-text2zx,
.sign-in-btn2zx {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-text2zx:hover {
  background: rgba(255, 255, 255, 0.171);
}
.button-text2zx:focus {
  outline-color: transparent;
  outline-style: solid;
}
.button-text2zx:active {
  background: rgba(255, 255, 255, 0.171);
  box-shadow: var(--recessed);
}
.button-text2zx {
  color: #dbdbdb;
  margin-top: 25.1px;
  width: 85%;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(122, 171, 216, 0.281);
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  box-shadow: var(--btn-rmazani);
  cursor: pointer;
}
.label2zx {
  position: relative;
  line-height: 22px;
}
.angel-of-the2zx {
  text-align: left;
  padding-left: 50px;
  color: white;
  font-size: large;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}
.lorem-ipsum-dolor5zx {
  position: relative;
  width: 85%;
  color: white;
  margin: 0 auto;
  line-height: 24px;
  font-weight: 500;
  font-family: var(--font-kalameh);
  text-align: justify;
  display: inline-block;
  margin-bottom: 200px;
  padding: 10px;
}

.div103zx {
  width: 8px;
  display: none;
}
.div103zx,
.label27zx {
  position: relative;
  line-height: 20px;
  background: linear-gradient(#545454, #545454), rgba(255, 255, 255, 0.23);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-14zx {
  flex: 1;
  border-radius: var(--br-81xl);
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-10xs);
}
.div105zx {
  width: 8px;
  display: none;
}
.div105zx,
.label29zx {
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-34zx,
.segmented-control4zx {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.button-34zx {
  flex: 1;
  box-shadow: var(--blur-shadow-small);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(255, 255, 255, 0.06);
  border: 0.5px solid var(--windows-stroke-glass-specular);
  height: 36px;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-10xs);
}
.segmented-control4zx {
  position: relative;
  width: 85%;
  margin: 0 auto;
  margin-top: 35px;
  border-radius: var(--br-81xl);
  background: linear-gradient(rgba(0, 0, 0, 0.336), rgba(0, 0, 0, 0.356));
  height: 44px;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
  font-size: var(--font-size-mini);
  color: var(--text-primary);
}
.profile4zx {
  position: absolute;
  top: 65px;
  left: calc(50% - 27.5px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-300);
  font-weight: 600;
}
.swatch101zx {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.person-icon22zx {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent60zx {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 6.16%;
  right: 5.33%;
  bottom: 87.44%;
  left: 80.8%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch102zx {
  position: relative;
  margin: 0 auto;
  height: 1025%;
  width: 9.76%;

  transform: rotate(90deg);
  transform-origin: 0 0;
}
.about-us1zx {
  width: 100%;
  height: 30px;
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.3);
  position: relative;
  color: white;
  margin-top: -270px;
  line-height: 22px;
}
.swatch-parent61zx {
  position: absolute;
  height: 3.45%;
  width: 76.53%;
  top: 16.75%;
  right: 11.73%;
  bottom: 79.8%;
  left: 11.73%;
  font-size: var(--font-size-mid);
}
.swatch-parent62zx,
.swatch-parent63zx {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 6.16%;
  right: 79.73%;
  bottom: 87.44%;
  left: 6.4%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: none;
  cursor: pointer;
}
.swatch-parent63zx {
  top: -27.09%;
  right: 116%;
  bottom: 120.69%;
  left: -29.87%;
}
.time24zx {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time22zx {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side22zx {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera22zx {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera22zx,
.statusbar-dynamicisland22zx,
.truedepth-camera22zx {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera22zx {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland22zx {
  width: 125px;
  position: relative;
}
.dynamic-island22zx {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal22zx,
.wifi-icon22zx {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon22zx {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon22zx {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery22zx {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side22zx,
.statusbar22zx {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side22zx {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar22zx {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  font-size: var(--font-size-base);
  color: var(--system-background-dark-base-primary);
  font-family: var(--font-gilroy-regular);
}
.about-uszx {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-semibold);
}
body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 375px) {
  .about-uszx {
    height: 100vh;
  }

  .swatch100zx {
    position: relative;
    top: -20px;
  }
  .button-text2zx {
    position: absolute;
    top: -36.30px;
  }
  .swatch99zx {
    position: absolute;
    top: 12%;
    right: 5.15%;
    bottom: 5.91%;
    left: 4.85%;
  }
}

/* 360 */
@media (max-width: 360px) {
  .swatch100zx {
    position: relative;
    top: -5px;
  }
  .button-text2zx {
    position: absolute;
    top: -18px;
  }
  .swatch99zx{
    height: 79%;
  }
  .swatch99zx {
    position: absolute;
    top: 12%;
    right: 5.15%;
    bottom: 5.91%;
    left: 4.85%;
  }

}

/* 344 */

@media (max-width: 344px) {
  .swatch99zx {
    position: absolute;
    top: 9.74%;
    right: 5.35%;
    bottom: 5.91%;
    left: 4.55%;
  }
  .swatch100zx {
    margin-top: 115px;
  }
  .button-text2zx {
    position: absolute;
    top: 8px;
  }
}
