.image-5-icon2b {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.swatch9b {
  border-top: solid 1px rgb(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);
  box-sizing: border-box;
  margin: 0 auto;
  width: 90%;
  height: 79%;
  position: absolute;
  top: 12%;
  right: 5.1%;
  bottom: 5.91%;
  left: 4.90%;
}

.angel-of-theb {
  position: absolute;
  top: 154px;
  left: 60px;
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  display: inline-block;
  width: 271px;
}
.swatch10b {
  position: relative;
  height: 47%;
  width: 85%;
  margin: 0 auto;
  margin-top: 280px;
  border-radius: var(--br-xl);
  background: rgba(12, 12, 12, 0.205);
  opacity: 50%;
}

.image-14-iconb {
  position: absolute;
  object-fit: contain;
  top: 310px;
  margin: 0 auto;
  border-radius: var(--br-13xl);
  width: 100%;
  height: 339px;
}

.filled-trackb,
.knobb,
.trackb {
  border-radius: var(--br-81xl);
}
.filled-trackb {
  position: absolute;
  height: 100%;
  width: 28.46%;
  top: 0;
  right: 71.54%;
  bottom: 0;
  left: 0;
  box-shadow: 5px 0 4px rgba(0, 0, 0, 0.18);
  background-color: var(--color-gray-100);
}
.knobb,
.trackb {
  overflow: hidden;
}
.trackb {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0-0.5px 1px rgba(255, 255, 255, 0.3) inset,
    0-0.5px 1px rgba(255, 255, 255, 0.25) inset,
    1px 1.5px 4px rgba(0, 0, 0, 0.08) inset,
    1px 1.5px 4px rgba(0, 0, 0, 0.1) inset;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    rgba(208, 208, 208, 0.5);
}
.knobb {
  width: 4px;
  position: relative;
  box-shadow: 0 0 8px 4px rgba(94, 94, 94, 0.4),
    0 0 8px 4px rgba(255, 255, 255, 0.2);
  background-color: var(--grays-white-dark);
  height: 4px;
  flex-shrink: 0;
}
.glow-positionb {
  position: absolute;
  width: 28.46%;
  top: calc(50% - 2px);
  right: 71.54%;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--padding-9xs);
  box-sizing: border-box;
}
.slider-default1b {
  position: absolute;
  top: 15px;
  left: 0;
  border-radius: var(--br-81xl);
  width: 100%;
  height: 12px;
}
.realb,
.x-rayb {
  position: absolute;
  top: 0;
  left: 4px;
  letter-spacing: -0.32px;
  line-height: 21px;
}
.x-rayb {
  left: 260px;
}
.po2{
  color: #1E1E1E;
  text-align: center;
  font-family: bold;
  font-size: 16px;
}

.realb3,
.x-rayb3 {
  position: absolute;
  top: 30px;
  left: -20px;
  letter-spacing: -0.32px;
  line-height: 21px;
}
.x-rayb3 {
  left: 260px;
}

.slider-default-parentb {
  position: absolute;
  top: 220px;
  left: calc(50% - 165.5px);
  width: 328px;
  height: 33px;
  font-size: var(--fonts-en-12-regular-size);
  color: var(--grays-white-dark);
}
.swatch11b {
  position: absolute;
  height: 95.19%;
  width: 105.05%;
  top: 0;
  right: -105.05%;
  bottom: 4.81%;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: 0.8px solid var(--color-gray-100);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.arrowbackward-icon1b {
  position: absolute;
  height: 53.85%;
  width: 53.94%;
  top: 23.08%;
  right: 23.03%;
  bottom: 23.08%;
  left: 23.03%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent3b,
.swatch-parent4b {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: 6.16%;
  right: 79.75%;
  bottom: 87.44%;
  left: 6.39%;
}
.swatch-parent4b {
  right: 5.32%;
  left: 80.81%;
}
.knob1b,
.toggleb {
  border-radius: var(--br-81xl);
}
.knob1b {
  width: 28px;
  position: relative;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.22);
  background-color: var(--grays-white-dark);
  height: 28px;
}
.toggleb {
  position: absolute;
  top: 0;
  left: calc(50% + 87px);
  box-shadow: 0-0.5px 1px rgba(94, 94, 94, 0.3) inset,
    0-0.5px 1px rgba(255, 255, 255, 0.2) inset,
    0 3px 3px rgba(128, 128, 128, 0.18) inset,
    0 3px 3px rgba(0, 0, 0, 0.15) inset;
  background: linear-gradient(
      rgba(208, 208, 208, 0.5),
      rgba(208, 208, 208, 0.5)
    ),
    #32d74b;
  width: 56px;
  height: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-9xs) var(--padding-11xs);
  box-sizing: border-box;
}
.show-problemb {
  position: absolute;
  top: 10px;
  left: -60px;
  line-height: 22px;
  width: 100px;
}
.show-problemb2 {
  position: absolute;
  top: -15px;
  left: 0px;
  line-height: 22px;
  width: 100px;
  font-style: bold;
}
.toggle-parentb {
  position: absolute;
  top: 140px;
  left: calc(50% - 142.5px);
  width: 300px;
  height: 32px;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--grays-white-dark);
}
.mandible1b {
  position: absolute;
  top: 66px;
  left: calc(50% - 42.5px);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-800);
}
.labelb {
  position: relative;
  line-height: 22px;
}
.button-textb,
.sign-in-btnb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-textb:hover {
  background: rgba(255, 255, 255, 0.171);
}
.button-textb:focus {
  outline-color: transparent;
  outline-style: solid;
}
.button-textb:active {
  background: rgba(255, 255, 255, 0.171);
  box-shadow: var(--recessed);
}
.button-textb {
  width: 85%;
  margin: 0 auto;
  margin-top: 20px;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(122, 171, 216, 0.281);
  height: 44px;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  box-shadow: var(--btn-rmazani);
  text-align: center;
  font-size: var(--fonts-en-12-regular-size);
  font-family: var(--font-gilroy-semibold);
}
.sign-in-btnb {
  position: absolute;
  top: 700px;
  left: calc(50% - 155.5px);
}
.time2b {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time2b {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side2b {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera2b {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera2b,
.statusbar-dynamicisland2b,
.truedepth-camera2b {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera2b {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland2b {
  width: 125px;
  position: relative;
}
.dynamic-island2b {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal2b,
.wifi-icon2b {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon2b {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon2b {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery2b {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side2b,
.statusbar2b {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side2b {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar2b {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--system-background-dark-base-primary);
}
.on-toggleb {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-semibold);
}
.po3{
  color: #1E1E1E;
  text-align: center;
  font-family: bold;
  font-weight: bold;
  line-height:50px;
  font-size: 16px;
  position: absolute;
top: 11.50%;
right: 2.78%;
bottom: 10.34%;
left: 2.60%;
}

body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 375px) {
  .on-toggleb {
    height: 100vh;
  }
  .po2{
   line-height: 40px;
  }

  .show-problemb {
    position: absolute;
    top: 5px;
    left: -26px;
  }
  .toggle-parentb {
    margin-top: -35px;
    position: absolute;
    left: calc(50% - 137.5px);
  }
  .slider-default-parentb {
    margin-top: -35px;
    left: calc(50% - 160.5px);
    width: 325px;
  }
  .swatch10b {
    margin-top: 230px;
  }
  .image-14-iconb {
    position: absolute;
    top: 220px;
    right: 80px;
    width: 200px;
    width: 200px;
  }
  .button-textb {
    margin-top: 10px;
  }
}

@media (max-width: 360px) {
  .button-textb {
    margin-top: 20px;
  }
  .show-problemb {
    position: absolute;
    top: 5px;
    left: -10px;
  }
  .show-problemb {
    position: absolute;
    top: 20px;
    left: 0px;
  }
  .toggle-parentb {
    position: absolute;
    left: calc(50% - 130.5px);
    margin-top: -10px;
    width: 280px;
  }
  .slider-default-parentb {
    position: absolute;
    left: calc(50% - 155.5px);
    margin-top: -15px;
    width: 310px;
  }
  .swatch10b {
    margin-top: 250px;
  }
  .image-14-iconb {
    position: absolute;
    top: 260px;
    right: 80px;
    width: 200px;
    width: 200px;
  }
}

@media (max-width: 344px) {
  .swatch9b {
    margin-top: -10px;
  }
  .show-problemb {
    position: absolute;
    top: 5px;
    left: -6px;
  }
  .toggle-parentb {
    position: absolute;
    left: calc(50% - 125.5px);
    margin-top: 10px;
    width: 270px;
  }
  .slider-default-parentb {
    position: absolute;
    left: calc(50% - 149.5px);
    margin-top: 10px;
    width: 301px;
  }
  .swatch10b {
    margin-top: 295px;
  }
  .image-14-iconb {
    position: absolute;
    top: 370px;
    right: 50px;
    width: 244px;
    width: 244px;
  }
  .button-textb {
    margin-top: 20px;
  }
}
