.image-5-icon21 {
  position: absolute;
  top: 0;
  left: -411px;
  width: 1232px;
  height: 812px;
  object-fit: cover;
}
.swatch93 {
  position: absolute;
  width: 90%;
  height: 79%;
  top: 12%;
  right: 5.15%;
  bottom: 5.91%;
  left: 4.90%;
  border-top: solid 1px rgba(192, 191, 191, 0.459);
  border-left: solid 1px rgba(192, 191, 191, 0.459);
  border-bottom: none;
  border-right: none;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-xl);
  background-color: var(--windows-glass);

  box-sizing: border-box;
}
.day,
.time22 {
  position: absolute;
  text-align: right;
}
.day {
  top: 89px;
  left: 81px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}
.time22 {
  top: -45px;
  left: 48px;
}
.score4 {
  top: 187px;
  left: 46px;
  text-align: right;
}
.day1,
.day2,
.score4 {
  position: absolute;
}
.day1 {
  top: 89px;
  left: 121px;
  text-align: right;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}
.day2 {
  left: 159px;
}
.day2,
.day3,
.day4 {
  top: 89px;
  text-align: right;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}
.day3 {
  position: absolute;
  left: 196px;
}
.day4 {
  left: 234px;
}
.day4,
.day5 {
  position: absolute;
}
.day5 {
  top: 89px;
  left: 272px;
  text-align: right;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}
.swatch94 {
  position: relative;
  margin: 0 auto;
  height: 30px;
  width: 100%;
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.3);
    margin-top: 5px;
}
.progress-chart1 {
  position: relative;
  text-align: center;
  margin-top: -30px;
  line-height: 30px;
}
.swatch100Xf {
  height: 60%;
  position: relative;
  margin: 0 auto;
  background: linear-gradient(rgba(7, 7, 7, 0), rgba(7, 7, 7, 0)),
    rgba(22, 22, 22, 0.151);
  width: 85%;
  opacity: 96%;
  box-shadow: var(--recessed);
}

.swatch-parent55 {
  position: absolute;
  height: 3.45%;
  width: 85%;
  top: 0%;
  right: 3%;
  bottom: 79.31%;
  left: 0%;
  font-size: var(--font-size-mid);
  font-family: var(--font-gilroy-semibold);
}
.fft{
    font-family: 'Courier New', Courier, monospace;
}
.swatch-parent556 {
  position: absolute;
  top: 12.75%;
  right: 3%;
  bottom: 79.31%;
  left: 7.73%;
}
.div79,
.div80 {
  position: absolute;
  top: 90.52%;
  left: 60%;
}
.div80 {
  top: 60.34%;
  left: 26.67%;
}
.div81,
.div82,
.group2 {
  position: absolute;
  top: 30.17%;
  left: 0;
}
.div82,
.group2 {
  top: 0;
  left: 6.67%;
}
.group2 {
  height: 14.29%;
  width: 4%;
  top: -3.82%;
  right: 82.93%;
  bottom: 89.53%;
  left: 13.07%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  text-align: right;
}
.div83,
.div84,
.div85 {
  position: absolute;
  top: 90.76%;
  left: 60%;
}
.div84,
.div85 {
  top: 45.38%;
  left: 26.67%;
}
.div85 {
  top: 68.07%;
  left: 33.33%;
}
.div86,
.div87,
.group3 {
  position: absolute;
  top: 22.69%;
  left: 40%;
}
.div87,
.group3 {
  top: 0;
  left: 0;
}
.group3 {
  height: 14.66%;
  width: 4%;
  top: 25.49%;
  right: 82.93%;
  bottom: 59.85%;
  left: 13.07%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  text-align: right;
}
.div88,
.div89,
.div90 {
  position: absolute;
  top: 0;
  left: 0;
}
.div89,
.div90 {
  left: 36px;
}
.div90 {
  left: 77px;
}
.div91,
.div92,
.div93,
.parent1 {
  position: absolute;
  top: 0;
  left: 120px;
}
.div92,
.div93,
.parent1 {
  left: 163px;
}
.div93,
.parent1 {
  left: 206px;
}
.parent1 {
  top: 327px;
  left: 76px;
  width: 217px;
  height: 11px;
  text-align: right;
}
.analyze-child1 {
  position: absolute;
  height: 13.67%;
  width: 64.53%;
  top: -3.08%;
  right: 16.27%;
  bottom: 89.41%;
  left: 19.2%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.w2 {
  position: relative;
  line-height: 22px;
}
.button-text39,
.sign-in-btn39 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button-text39 {
  width: 38px;
  backdrop-filter: blur(50px);
  border-radius: var(--br-481xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    0 0;
  height: 21px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
}
.sign-in-btn39 {
  top: -66px;
  left: calc(50% - 111.5px);
  box-shadow: var(--btn-rmazani);
  justify-content: flex-start;
  font-size: var(--font-size-sm);
  font-family: var(--font-gilroy-semibold);
}
.m3,
.sign-in-btn39,
.w3 {
  position: absolute;
}
.w3 {
  top: 0;
  left: 0;
  line-height: 22px;
}
.m3 {
  left: 41px;
}
.m3,
.m4,
.m5 {
  top: 0;
  line-height: 22px;
}
.m4 {
  position: absolute;
  left: 80px;
}
.m5 {
  left: 121px;
}
.m5,
.w-parent,
.y1 {
  position: absolute;
}
.y1 {
  top: 0;
  left: 162px;
  line-height: 22px;
}
.w-parent {
  top: -66px;
  left: 136px;
  width: 176px;
  height: 22px;
  font-size: var(--font-size-sm);
  font-family: var(--font-gilroy-semibold);
}
.group-icon1,
.vector-icon3 {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
}
.group-icon1 {
  height: 11.38%;
  width: 80%;
  top: 22.71%;
  right: 11.28%;
  bottom: 60.91%;
  left: 10.24%;
  max-height: 100%;
}
.vector-icon3 {
  width: 97.98%;
  top: 82.09%;
  right: 1.62%;
  left: 0.4%;
  height: 0;
}
.vector-icon4 {
  position: absolute;
  height: 82.84%;
  top: 0;
  bottom: 17.16%;
  left: 0;
  max-height: 100%;
  width: 0;
  object-fit: contain;
}
.qty1 {
  position: absolute;
  top: 114px;
  left: 231px;
  line-height: 20px;
}
.vector-parent1 {
  position: absolute;
  height: 16.5%;
  width: 65.87%;
  top: 25.62%;
  right: 14.93%;
  bottom: 57.88%;
  left: 19.2%;
}
.slider-default10 {
  position: absolute;
  top: 136px;
  left: 331px;
  border-radius: var(--br-81xl);
  width: 12px;
  height: 508px;
  overflow: hidden;
  object-fit: contain;
}
.div94 {
  width: 8px;
  display: none;
}
.div94,
.label24 {
  position: relative;
  line-height: 20px;
  background: linear-gradient(#969696, #b8b8b8), rgba(255, 255, 255, 0.425);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-13 {
  flex: 1;
  border-radius: var(--br-81xl);
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-10xs);
}
.div95 {
  width: 8px;
  display: none;
}
.div95,
.label25 {
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div99,
.label29 {
  position: relative;
  line-height: 20px;
  background: linear-gradient(#ffffff, #fdfdfd), rgb(255, 255, 255);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-23,
.button-29,
.segmented-control3 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.segmented-control33 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.button-23 {
  flex: 1;
  box-shadow: var(--blur-shadow-small);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.18), rgba(94, 94, 94, 0.18)),
    rgba(122, 171, 216, 0.281);
  height: 36px;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-10xs);
}
.rg-scroller{
  background: rgba(0, 0, 0, 0.25);
  height: 900px;
  width: 100%;
  z-index: 1;
  opacity: 0;
  position: absolute;
}
.charttt{
width: 110%;
}
.chartt1{
  margin-top: -10px;
}
.chartt11{
  margin-top: 30px;
}
.segmented-control3 {
  position: relative;
  width: 85%;
  margin: 0 auto;
  margin-top: 670px;
  border-radius: var(--br-81xl);
  background: rgba(12, 12, 12, 0.205);
  height: 44px;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
  font-size: var(--font-size-mini);
  color: var(--text-primary);
  font-family: var(--font-gilroy-semibold);
}
.button-29 {
  flex: 1;
  box-shadow: var(--blur-shadow-small);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(94, 94, 94, 0.13), rgba(94, 94, 94, 0.13)),
    rgba(255, 255, 255, 0.3);
  height: 36px;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-10xs);
}
.segmented-control33 {
  position: relative;
  width: 91%;
  z-index: 999;
  margin-top: 0px;

  height: 44px;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
  font-size: var(--font-size-mini);

  font-family: var(--font-gilroy-semibold);
}
.study-result {
  position: relative;
  text-align: center;
  margin-top: -30px;
  line-height: 30px;
}
.swatch-parent56 {
  position: absolute;
  height: 3.45%;
  width: 85%;
  right: 3%;
  left: .15%;
  top: 150%;
  bottom: 46.55%;
  font-size: var(--font-size-mini);
  font-family: var(--font-gilroy-semibold);
}
.swatch-parent567 {
  position: absolute;
  height: 3.45%;
  width: 85%;
  right: 3%;
  left: .15%;
  top: 80%;
  bottom: 46.55%;
  font-size: var(--font-size-mini);
  font-family: var(--font-gilroy-semibold);


  
}
.correct-answers {
  position: absolute;
  top: 600px;
  left: 59px;
  font-size: var(--fonts-en-12-regular-size);
  line-height: 20px;
  text-align: left;
}
.analyze-child2 {
  position: absolute;
  top: 604px;
  left: 40px;
  border-radius: var(--br-9xs);
  background-color: var(--color-yellowgreen);
  width: 12px;
  height: 12px;
}
.unstudied {
  position: absolute;
  top: 624px;
  left: 211px;
  font-size: var(--fonts-en-12-regular-size);
  line-height: 20px;
  text-align: left;
}
.analyze-child3 {
  position: absolute;
  top: 628px;
  left: 191px;
  border-radius: var(--br-9xs);
  background-color: #6f58e9;
  width: 12px;
  height: 12px;
}
.incorrect-answers,
.studied {
  position: absolute;
  top: 600px;
  left: 211px;
  font-size: var(--fonts-en-12-regular-size);
  line-height: 20px;
  text-align: left;
}
.incorrect-answers {
  top: 624px;
  left: 59px;
}
.analyze-child4,
.analyze-child5 {
  position: absolute;
  top: 604px;
  left: 191px;
  border-radius: var(--br-9xs);
  background-color: #2d99fe;
  width: 12px;
  height: 12px;
}
.analyze-child5 {
  top: 627px;
  left: 40px;
  background-color: #f52727;
}
.swatch96 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  box-shadow: var(--blur-shadow-small);
  backdrop-filter: blur(100px);
  border-radius: var(--br-13xl);
  background-color: var(--windows-glass);
  border: 0.8px solid var(--color-gray-100);
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.arrowbackward-icon13 {
  position: absolute;
  height: 53.85%;
  width: 53.85%;
  top: 23.08%;
  right: 23.08%;
  bottom: 23.08%;
  left: 23.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.swatch-parent57 {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: 6.16%;
  right: 79.73%;
  bottom: 87.44%;
  left: 6.4%;
}
.study-time3 {
  position: absolute;
  top: 3px;
  left: 101px;
  line-height: 22px;
}
.swatch-parent58 {
  position: absolute;
  height: 3.45%;
  width: 76.53%;
  top: -14.04%;
  right: 13.87%;
  bottom: 110.59%;
  left: 9.6%;
  font-size: var(--font-size-mid);
  font-family: var(--font-gilroy-semibold);
}
.profile3 {
  position: absolute;
  top: 65px;
  left: calc(50% - 27.5px);
  font-size: var(--font-size-xl);
  letter-spacing: -0.32px;
  line-height: 21px;
  font-family: var(--font-gilroy-bold);
  color: var(--color-gray-300);
}
.swatch-parent59 {
  position: absolute;
  height: 6.4%;
  width: 13.87%;
  top: 6.16%;
  right: 5.33%;
  bottom: 87.44%;
  left: 80.8%;
}
.average1 {
  position: absolute;
  top: 0;
  left: 20px;
  line-height: 20px;
}
.group-child6 {
  position: absolute;
  top: 3px;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-yellowgreen);
  width: 12px;
  height: 12px;
}
.score5 {
  position: absolute;
  top: 0;
  left: 110px;
  line-height: 20px;
}
.group-child7 {
  position: absolute;
  top: 2px;
  left: 92px;
  border-radius: var(--br-9xs);
  background-color: var(--color-turquoise);
  width: 12px;
  height: 12px;
}
.average-parent {
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  width: 134px;
  height: 20px;
  float: left;
  margin: 10px;
  font-size: var(--fonts-en-12-regular-size);
}
.average-parent1 {
  position: relative;
  margin: 0 auto;
  margin-top: 80px;
  width: 134px;
  height: 20px;
  float: left;
  margin: 10px;
  font-size: var(--fonts-en-12-regular-size);
}

.analyze-child6,
.analyze-child7 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.analyze-child6 {
  position: relative;
  margin: 0 auto;
  margin-top: -130px;
  margin-bottom: 150px;
  height: 12.83%;
  width: 80%;
}
.analyze-child7 {
  height: 21.67%;
  width: 46.93%;
  top: 52.96%;
  right: 29.33%;
  bottom: 25.37%;
  left: 23.73%;
}
.analyze-child7,
.div97,
.div98 {
  position: absolute;
}
.div97 {
  top: 489px;
  left: 244px;
  font-size: var(--font-size-3xs);
  line-height: 20px;
  font-family: var(--font-gilroy-semibold);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20.4px;
  height: 21.4px;
}
.div98 {
  top: 500px;
  left: 188px;
  font-size: var(--fonts-en-12-regular-size);
}
.div100,
.div101,
.div98,
.div99 {
  line-height: 20px;
  font-family: var(--font-gilroy-semibold);
}
.div99 {
  position: absolute;
  top: 518px;
  left: 145px;
  font-size: var(--fonts-en-12-regular-size);
}
.div100,
.div101 {
  font-size: var(--font-size-3xs);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21.4px;
}
.div100 {
  position: absolute;
  top: 570px;
  left: 115px;
  width: 20.4px;
}
.div101 {
  top: 479px;
  left: 100px;
  width: 18.2px;
}
.div101,
.div102,
.time23 {
  position: absolute;
}
.div102 {
  top: 445px;
  left: 134px;
  font-size: var(--font-size-3xs);
  line-height: 20px;
  font-family: var(--font-gilroy-semibold);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18.2px;
  height: 21.4px;
}
.time23 {
  top: 1px;
  left: 0;
  letter-spacing: -0.32px;
  line-height: 21px;
  display: inline-block;
  width: 54px;
  height: 20px;
}
.statusbar-time21 {
  width: 54px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 21px;
}
.left-side21 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-10xs) var(--padding-3xs);
}
.truedepth-camera21 {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 62.5px);
  width: 80px;
  overflow: hidden;
}
.facetime-camera21,
.statusbar-dynamicisland21,
.truedepth-camera21 {
  border-radius: var(--br-81xl);
  background-color: var(--system-background-dark-base-primary);
  height: 37px;
}
.facetime-camera21 {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% + 25.5px);
  width: 37px;
  overflow: hidden;
}
.statusbar-dynamicisland21 {
  width: 125px;
  position: relative;
}
.dynamic-island21 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-mobile-signal21,
.wifi-icon21 {
  width: 18px;
  position: relative;
  height: 12px;
}
.wifi-icon21 {
  width: 17px;
  height: 11.8px;
}
.statusbar-battery-icon21 {
  width: 27.4px;
  position: relative;
  height: 13px;
}
.signal-wifi-battery21 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.right-side21,
.statusbar21 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right-side21 {
  align-self: stretch;
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-2xs) 0 0;
}
.statusbar21 {
  position: absolute;
  top: 0;
  left: -9px;
  width: 393px;
  height: 59px;
  align-items: flex-end;
  font-size: var(--font-size-base);
  color: var(--system-background-dark-base-primary);
  font-family: var(--font-gilroy-regular);
}
.analyze1 {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--grays-white-dark);
  font-family: var(--font-gilroy-semibold);
}
.scrollbar{
  width: 100%;
  height: 550px;
  overflow-y: scroll;
  overflow-x: none;
}

.scrollbar::-webkit-scrollbar{
  display: none;
}
body {
  width: 100%;
  position: relative;
  background-color: var(--grays-white-dark);
  background-image: url("../../../public/img/bg1.62799f21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 375px) {
  .analyze1 {
    height: 100vh;
  }
  .average-parent1{
    margin-top: 180px;
  }
  .charttt{
    margin-top: -20px;
  }
  .chartt1{
    margin-top: -20px;
    height: 110px;
  }
  .scrollbar{
    height: 450px;
  }
  .swatch-parent567{
    margin-top: 35px;
  }
  .swatch-parent56{
    margin-top: 70px;
  }
 .average-parent{
  margin-top: 220px;
  margin-right: 30px;
 }
  .segmented-control33{
    margin-top: 20px;
  }
}
@media (max-width: 375px) {
  .segmented-control3 {
    position: absolute;
    top: -122px;
    right: 25px;
  }
  .label24,.label25{
    font-size: 12px;
  }
}

@media (max-width: 375px) {
  .analyze-child6 {
    position: absolute;
    top: 510px;
    right: 25px;
  }
}

@media (max-width: 375px) {
  .pp1 {
    position: absolute;
    top: 220px;
  }
  .average-parent {
    position: absolute;
    top: 150px;
    right: 135px;
  }
}

/* 360 */

@media (max-width: 360px) {
  .segmented-control3 {
    position: absolute;
    top: -53px;
    right: 25px;
  }
  .average-parent{
    margin-top: 170px;
  }
  .swatch-parent567{
    margin-top: 5px;
  }
  .swatch-parent56{
    margin-top: 15px;
  }
}

@media (max-width: 360px) {
  .analyze-child6 {
    position: absolute;
    top: 560px;
    right: 25px;
  }
}

@media (max-width: 360px) {
  .pp1 {
    position: absolute;
    top: 220px;
  }
  .average-parent {
    position: absolute;
    top: 200px;
    right: 135px;
  }
  .scrollbar{
    height: 500px;
  }
}

/* 344 */

@media (max-width: 344px) {
  .segmented-control3 {
    position: absolute;
    top: 75px;
    right: 25px;
  }
  .swatch-parent56{
    margin-top: -110px;
  }
  .swatch-parent567{
    margin-top: -50px;
  }
  .scrollbar{
    height: 600px;
  }
}

@media (max-width: 344px) {
  .analyze-child6 {
    position: absolute;
    top: 660px;
    right: 25px;
  }
}

@media (max-width: 344px) {
  .pp1 {
    position: absolute;
    top: 140px;
  }
  .average-parent {
    margin-top: 180px;
    
  }
  
}
