.modal-overlayr {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-contentr {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-headerr {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

.modal-bodyr {
  margin-bottom: 20px;
}

.modal-footerr {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.form-controlr {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.btnr {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primaryr {
  background-color: #007bff;
  color: #fff;
}

.btn-primaryr:hover {
  background-color: #0056b3;
}

.btn-dangerr {
  background-color: #dc3545;
  color: #fff;
}

.btn-dangerr:hover {
  background-color: #a71d2a;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
